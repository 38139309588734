import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { CustomerService } from '../customer.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.less']
})
export class CreateCustomerComponent implements OnInit, OnDestroy {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: any;
  //CreateCustomerData;
  type = ['success', 'error', 'info', 'warning'];
  options: GlobalConfig;
  customerRegex = /^[^,`=~#!<>+@]+$/;
  //cidnRegex = /^[0-9]+$/;
  cidnRegex =  /^[0-9]{10}$/g;
  abnRegex = /^\d{11}$/;
  msoIdRegex = /^[^,`=~#!<>+@]+$/;
  public businessNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter Business Name',
    id: 'businessName',
    tabindex: '',
    maxlength: '50',
    headerText: 'Business Name',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter values except ,`=~#!<>+@',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'businessName',
    formControlName: 'businessName',
    isDisabled: false,
    showValidationIcon: true
  };
  public cidnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter CIDN',
    id: 'cidn',
    tabindex: '',
    maxlength: '10',
    headerText: 'CIDN',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter only numeric values',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'cidn',
    formControlName: 'cidn',
    isDisabled: false,
    showValidationIcon: true
  };

  public spidModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: 'Enter SPID',
    id: 'spid',
    tabindex: '',
    maxlength: '5',
    headerText: 'SPID',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: 'Please enter SPID in numeric format, SPID must be upto 5 digits',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'spid',
    formControlName: 'spid',
    isDisabled: false,
    showValidationIcon: true
  };

  public statusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  public abnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessAbn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessAbn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserAbnValidationErrMsg',
    placeholderCaption: 'Enter ABN',
    id: 'abn',
    tabindex: '',
    maxlength: '11',
    headerText: 'ABN',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'abn',
    formControlName: 'abn',
    isDisabled: false,
    showValidationIcon: false
  };
  public msoIdModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessMsoId',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessMsoId',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserMsoIdValidationErrMsg',
    placeholderCaption: 'Enter MSO Id',
    id: 'msoid',
    tabindex: '',
    maxlength: '15',
    headerText: 'MSOID',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'msoid',
    formControlName: 'msoid',
    isDisabled: false,
    showValidationIcon: false
  };

  public createCustomerForm: FormGroup;

  constructor(private fb: FormBuilder,
    private customerService: CustomerService,
    private loader: LoadingServiceService,
    private toastr: ToastrService,
    private router: Router,
    // private userService: UsersService, 
    private utility: UtilityService,
    private titleService: Title,
    private sharedService: SharedService
  ) {
    this.titleService.setTitle('Amplitel Create a Customer');
    this.options = this.toastr.toastrConfig;
  }
  public statusItems = [
    "Active",
    "Inactive"
  ];
  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.createForm();
  }

  createForm() {
    this.createCustomerForm = this.fb.group({
      businessName: ['', [Validators.required, Validators.pattern(this.customerRegex)]],
      cidn: ['', [Validators.required, Validators.pattern(this.cidnRegex)]],
      spid: ['00', [Validators.required, Validators.pattern(this.utility.getShortNumberRegEx())]],
      status: [undefined, [Validators.required]],
      abn: ['', [Validators.pattern(this.abnRegex)]],
      msoid: ['', [Validators.pattern(this.msoIdRegex)]],
    });
    this.getLabels();
  }

  getLabels() {
    this.customerService.getCreateMyUserModel().then((data: any) => {
      this.aemLabelAuthoring = data;
      this.statusItems = this.aemLabelAuthoring.form.statusItems;
      this.businessNameModel.headerText = this.aemLabelAuthoring.form.businessName;
      this.businessNameModel.placeholderCaption = this.aemLabelAuthoring.form.businessNamePlaceholder;
      this.businessNameModel.validationMessage = this.aemLabelAuthoring.form.businessNameValidationMessage;
      this.businessNameModel.requiredText = this.aemLabelAuthoring.form.businessNameRequiredMessage;
      this.spidModel.headerText = this.aemLabelAuthoring.form.spid;
      this.spidModel.placeholderCaption = this.aemLabelAuthoring.form.spidPlaceholder;
      this.spidModel.validationMessage = this.aemLabelAuthoring.form.spidValidationMessage;
      this.spidModel.requiredText = this.aemLabelAuthoring.form.spidRequiredMessage;
      this.cidnModel.headerText = this.aemLabelAuthoring.form.cidn;
      this.cidnModel.placeholderCaption = this.aemLabelAuthoring.form.cidnPlaceholder;
      this.cidnModel.validationMessage = this.aemLabelAuthoring.form.cidnValidationMessage;
      this.cidnModel.requiredText = this.aemLabelAuthoring.form.cidnRequiredMessage;
      this.abnModel.headerText = this.aemLabelAuthoring.form.abn;
      this.abnModel.placeholderCaption = this.aemLabelAuthoring.form.abnPlaceholder;
      this.abnModel.validationMessage = this.aemLabelAuthoring.form.anbValidationMessage;
      this.msoIdModel.headerText = this.aemLabelAuthoring.form.msoid;
      this.msoIdModel.placeholderCaption = this.aemLabelAuthoring.form.msoidPlaceholder;
      this.msoIdModel.validationMessage = this.aemLabelAuthoring.form.msoidValidationMessage;

    }).catch((error) => {
    });
  }

  onSubmit(): any {
    let errMsgs:string = 'Unable to create/update the customer account in Enquiry System, please contact Level-1 support team';
    // this.loader.setLoader(true);
    const DATA = this.createCustomerForm.value;
    this.customerService.createCustomer(DATA).then((response) => {
      // this.loader.setLoader(false);
      this.customerService.createCustomerFormData = this.createCustomerForm.value;
      this.customerService.createCustomerFormData['page'] = 'Create';
      this.router.navigate(['manageCustomers/customers']);
    }).catch((err) => {
      if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
        this.showToasterMessage(this.aemLabelAuthoring.form.errMsg, '', this.options, 'error');
      } else {
        const msg = err.error.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      }      
    });
  }

  cancelCreate() {
    this.toastr.clear();
    this.createCustomerForm.reset();
    this.createCustomerForm.markAsUntouched();
    this.router.navigate(['manageCustomers/customers']);
  }

  showToasterMessage(message: string,header: string,options: GlobalConfig,type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss=true;
    this.options.timeOut=5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message,header === '' ? null : header,options,this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }
  ngOnDestroy() {
   // this.toastr.clear();
  }
}



