<div class="modal" tabindex="-1" role="dialog" [ngClass]="{'defaultModal':isOpenPopUp}"
    aria-labelledby="dialog1Title">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="primaryBusinessGroup" id="primaryBusinessForm" #primaryBusinessForm="ngForm">
                <div class="container">
                    <div class="row m-0">
                        <div class="select-primary-business">
                            <h2>  Welcome </h2>
                            <p id="dialog1Title">As your profile is associated with multiple business entities please select the entity you would like to set.</p>
                            <label for="defaultBusinessNames">Default business entity</label>
                            <app-cui-searchable-dropdown name="defaultBusinessNames"
                                [formControlName]="'defaultBusinessNames'" [(ngModel)]="defaultBusinessName"
                                [model]="{accessibleHeaderId: 'business-name'}" [labelName]="'Select service type'"
                                [placeholder]="'Select Business Name'" [isRequired]=true [items]=profileBusinessNamesList
                                automationType="dropdown" automationId="drpdwnbusinessType" [noAutoComplete]=false
                                (ngModelChange)='onBusinessNameChanged($event)' 
                                [ngClass]="{'invalid-form-control': (primaryBusinessGroup.controls.defaultBusinessNames.invalid && primaryBusinessGroup.controls.defaultBusinessNames.touched)}"
                                id="defaultBusinessNames" class="user-dropDown" required>
                            </app-cui-searchable-dropdown>
                            <button class="red-primary-btn xl-btn business-btn-ok"
                                (click)="closeModal()">Ok</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
