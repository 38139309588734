import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { AuthService, HamburgerMenuModule } from 'tw-core-ui';
import { CommonService } from './services/common.service';
import { LoadingServiceService } from './services/loading-service.service';
import { TranslateService } from 'ng2-translate';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'com-towcohub-frontend-spa';
  showLoading: Boolean = true;
  showCustomLoading: Boolean = true;
  showContentFlag: Boolean = false;
  showCustomValue = 0;
  isAcknoledged = true;
  status = [];
  private env: any = window['environment'];

  public primaryBusinessGroup: FormGroup;
  
  public defaultBusinessName;
  public profileBusinessNamesList: any = [];
  public allBusinessRoleData: any;
  public primaryBusinessName;
  public selectedCIDN;
  public selectedBusiness;
  profile: any = {};
  isOpenPopUp = false;
  isAllReady = false;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    // return false;
  }

  constructor(private fb: FormBuilder,private profileService: ProfileService,private authService: AuthService, private router: Router,
    private loader: LoadingServiceService, private commonService: CommonService,private translate:TranslateService
  ) {
    // this.isOpenPopUp = true;
    
    this.primaryBusinessGroup = this.fb.group({
      defaultBusinessNames: ['', [Validators.required]]
    })

    this.translate.addLangs(["en","fr","cn","hi"]);
    this.translate.setDefaultLang("en");
    this.translate.use("en");
    // sessionStorage.setItem('contactName', 'John Smith');
    // sessionStorage.setItem('email', 'john.smith@gmail.com');
    // sessionStorage.setItem('cidn', '1234567890');
    //sessionStorage.setItem('firstName', 'Ayush');
    //sessionStorage.setItem('lastName', 'Sahu'); 
    this.loader.getLoader().subscribe(load => this.showLoading = load);
    this.loader.getCustomLoader().subscribe(load => this.showCustomLoading = load);
    this.loader.getCustomLoaderValue().subscribe(value => this.showCustomValue = value);
    this.showLoading = true;
    if (this.env.production) {
      if (window.location.href.search('error_description') > -1) {
        window.location.href = window['userManagerObj'].unauthorized_access_uri;
      } else {
        this.authService.completeAuthentication().then(() => {
          this.authService.getUserInfo().then((res) => {
            const CIDN_LIST = this.commonService.getroleDetails();
            const roles = CIDN_LIST.roleList;
            if (roles.length == 0) {
              window.location.href = window['userManagerObj'].unauthorized_access_uri;
            } else {
              // this.showContentFlag = true;
              sessionStorage.setItem('contactName', res.given_name + ' ' + res.family_name);
              sessionStorage.setItem('firstName', res.given_name);
              sessionStorage.setItem('lastName', res.family_name);
              sessionStorage.setItem('email', res.username);
              sessionStorage.setItem('cidn', res.roles[0].value.split(":")[0]);
              this.showLoading = false;
              if (CIDN_LIST.cidnList && CIDN_LIST.cidnList.length) {
                this.commonService.getCustomerStatus({ 'cidnList': CIDN_LIST.cidnList.join() }).then(result => {
                  const customerData = result.data['Customer status'];
                  if (customerData.length > 0) {
                    for (let i = 0; i < customerData.length; i++) {
                      if (customerData[i].status.toLowerCase() === 'active') {
                        this.status.push(customerData[i].status);
                      }
                    }

                    if (sessionStorage.getItem('isLoginFlag')) {
                      this.navigate();
                    } 
                    else {
                      this.isOpenPopUp = false;
                      this.showLoading = false;
                      this.showContentFlag = true;
                      this.loader.setCustomLoader(false);
                      this.loader.setLoader(false);
                      this.router.navigateByUrl('/terms-and-conditions');
                    }

                  }
                }).catch((err) => {
                  this.router.navigate(['AMS']);
                  this.showLoading = false;
                  this.showContentFlag = true;
                });
              }
            }
          });
        });
      }
    }
    else {
      if (sessionStorage.getItem('isLoginFlag')) {
        this.navigate();
      } 
      else {
        this.isOpenPopUp = false;
        this.showLoading = false;
        this.showContentFlag = true;
        this.loader.setCustomLoader(false);
        this.loader.setLoader(false);
        this.router.navigateByUrl('/terms-and-conditions');
      }
      
    }
  }
  ngOnInit(): void {
    // if (window.location.pathname != '/set-default-business') {
    //   if (!this.commonService.getPrimaryValue()['businessName']) {
    //     this.router.navigateByUrl('/set-default-business');
    //   }
    // }

    this.commonService.setTermsAndCondSubject.subscribe(result => {
      this.isOpenPopUp = false;
      this.showContentFlag = false;
      this.navigate();
    })
    
    this.router.events.subscribe((router) => {
      if (router instanceof NavigationEnd) {
        // if (sessionStorage.getItem('isLoginFlag') || this.router.url === '/terms-and-conditions' || this.router.url === '/AMS' || this.router.url === '/acknowledge') {
        //   this.loader.setCustomLoader(false);
        //   this.loader.setLoader(false);
        //   this.isOpenPopUp = false;
        // } 
        // else {
        //   this.isOpenPopUp = false;
        //   this.router.navigateByUrl('/terms-and-conditions');
        // }
      }
    });


  }

  navigate() {
    this.loader.setLoader(true);
    this.profile = this.profileService.getProfileDetails();
    this.commonService.setAdminAndBusiness();
    if (this.commonService.isAmplitelAdmin) {
      this.isOpenPopUp = false;
      this.commonService.updatePrimaryBusinessNameRoleForAmplitelAdmin();
      this.navigateUrl();
      this.loader.setLoader(false);
      this.isAllReady = true;
    } else {
      this.getAllBusinessNames();
    }
  }

  closeModal() {
    this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedBusiness[0]['CIDN'], abn: this.selectedBusiness[0]['ABN'], msoId: this.selectedBusiness[0]['MSO_ID'] });
    // this.router.navigateByUrl('/site-locator')
    this.isOpenPopUp = false;
    if (this.router.url === '/terms-and-conditions' || this.router.url === '/AMS' || this.router.url === '/acknowledge') {
      this.commonService.userReport('login')
        .then(res=>console.log('Login recorded'))
        .catch(err=>console.log('Login reporting failed'));
      this.showContentFlag = true;
      this.router.navigateByUrl('/site-locator');
    } else {
      this.navigateUrl();
    }
  }

  onBusinessNameChanged(newValue) {
    if (newValue) {
      this.primaryBusinessName = newValue;
      this.selectedBusiness = this.allBusinessRoleData.filter(key => {
        return key['Business name'].toLowerCase() === this.primaryBusinessName.toLowerCase();
      })
      // .map(key => {
      //   return key['CIDN'];
      // });
    }
  }



  
 
  navigateUrl() {
    this.commonService.userReport('login')
      .then(res=>console.log('Login recorded'))
      .catch(err=>console.log('Login reporting failed'));
    //console.log(this.authService.user.state);
    //console.log(this.status, this.status.length);
    if(this.env.production) {
      if (this.authService.user.state) {
        if (this.status && this.status.length > 0) {
          this.router.navigate([this.authService.user.state]);
          this.showContentFlag = true;
        } else {
          window.location.href = window['userManagerObj'].unauthorized_access_uri;
        }
      } else {
        if (this.status && this.status.length > 0) {
          this.showLoading = false;
          this.showContentFlag = true;
          if (sessionStorage.getItem('isLoginFlag') && this.router.url === '/terms-and-conditions') {
            this.router.navigateByUrl('/')
          }
        } else {
          window.location.href = window['userManagerObj'].unauthorized_access_uri;
        }
      }
    }
    else {
      this.showLoading = false;
      this.showContentFlag = true;
      if (sessionStorage.getItem('isLoginFlag') && this.router.url === '/terms-and-conditions') {
        this.router.navigateByUrl('/')
      }
    }
  }
  

  getAllBusinessNames() {
    this.commonService.getAllBusinessNames().then(res => {
      const allBusinessRoleData: any = res;
      this.allBusinessRoleData = res;
      if (allBusinessRoleData.length > 0) {
        (allBusinessRoleData || []).forEach(val => {
          if (this.profileBusinessNamesList.indexOf(val['Business name']) === -1) {
            this.profileBusinessNamesList.push(val['Business name']);
          }
        });
        this.profileBusinessNamesList.sort(this.itemSort);
      }
      if (this.profileBusinessNamesList.length == 1) {
        this.primaryBusinessName = this.profileBusinessNamesList[0];
        this.selectedBusiness = this.allBusinessRoleData.filter(key => {
          return key['Business name'].toLowerCase() === this.primaryBusinessName.toLowerCase();
        })
        // .map(key => {
        //   return key['CIDN'];
        // });
        // this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedCIDN[0] });
        this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedBusiness[0]['CIDN'], abn: this.selectedBusiness[0]['ABN'], msoId: this.selectedBusiness[0]['MSO_ID'] });
        // this.router.navigateByUrl('/site-access');
        this.isOpenPopUp = false;
        this.isAllReady = true;
        this.navigateUrl();
      } else {
        
        this.isOpenPopUp = true;
        this.isAllReady = true;
      }
      this.loader.setLoader(false);
    }).catch((err) => {
      console.log(err);
      // this.router.navigateByUrl('/error');
    });
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    }
    else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

}
