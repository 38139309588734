import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { AddPreferenceData } from '../notification.model';
import { CompanyNotificationService, IModalData, INotificationData } from '../company-notification.service';
import { CommonService } from "../../services/common.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertBoxAdminNotificationPrefComponent } from '../alert-box-admin-notification-pref/alert-box-admin-notification-pref.component';
import { UsersService } from '../../users/users.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-add-preference',
  templateUrl: './add-preference.component.html',
  styleUrls: ['./add-preference.component.less']
})
export class AddPreferenceComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: AddPreferenceData;
  public businessRoleDetails: any;
  public businessNameItems = [];
  public notificationTypeItems = [];
  userRole = [];
  public notificationPreferenceItems = [];
  type = ['success', 'error', 'info', 'warning'];
  options: GlobalConfig;
  public businessNames: any = [];
  public moreThanOneBusiness: Boolean = false;
  public cidns: any = [];
  update: Boolean = false;
  editData: INotificationData;
  public phoneNumber;

  public businessNameModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness Name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public notificationTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Notification Type Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public notificationPreferenceModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'notificationPreferenceHeader'
  };
  public emailAddressModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblEmailAddress',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxemailAddress',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divemailAddressValidationErrMsg',
    placeholderCaption: '',
    id: 'emailAddress',
    tabindex: '',
    maxlength: '100',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'emailAddress',
    formControlName: 'emailAddress',
    isDisabled: false,
    showValidationIcon: false
  };
  public loggedInEmail: string;
  public addPreferenceForm: FormGroup;
  constructor(private fb: FormBuilder, private notificationService: CompanyNotificationService, 
    private toastr: ToastrService, private router: Router, 
    private utility: UtilityService, 
    private commonService: CommonService, private modalService: NgbModal, 
    private usersService: UsersService,
    private titleService: Title,
    private sharedService: SharedService) {    
    this.editData = this.notificationService.getSelectedNotificationsData();
    if(this.editData){
      this.titleService.setTitle('Amplitel Edit Preferences');
    } else {
      this.titleService.setTitle('Amplitel Add Preferences');
    }
    this.options = this.toastr.toastrConfig;
    //this.userRole = (this.commonService.getroleDetails()).roleList;
  }

  async ngOnInit(): Promise<void> {
    this.sharedService.focusOnElement('header_logo');
    if (this.getLabels() !== null)
    {
      await this.getLabels();

    }
    const ROLES = this.commonService.getroleDetails();
    this.loggedInEmail = this.notificationService.getProfileDetails() ? this.notificationService.getProfileDetails().contactEmail : '';
    if (this.loggedInEmail) {
      this.getUserProfileData();
    }
    
    if (ROLES && ROLES.cidnList && ROLES.cidnList.length) {
      this.cidns = ROLES.cidnList;
      this.userRole = ROLES.roleList;
      this.getBusinessNames();
    }
    this.update = this.editData ? true : false;
    if (this.editData) {
      this.update = true;
      this.createForm();
    } else {
      this.update = false;
      this.createForm();
    }
  }
  async getUserProfileData() {
    const data = { 'userName': this.loggedInEmail };
    await this.usersService.getUserData(data, 'profile').then(async res => {
      const userData = await res['data']['wholesale-users'][0];
      this.phoneNumber = userData.phoneNumbers[0].value;
    }), err => {
      this.phoneNumber = undefined;
    }
  }

  canDeactivate() {
    const msg = this.aemLabelAuthoring ? this.aemLabelAuthoring.form.alertBox.leavePageMsg : 'Are you sure ?';
    if (this.update && this.addPreferenceForm.dirty) {
      return window.confirm(msg);
    } else {
      return true;
    }
  }
  showModal(heading: string) {
    const DATA: IModalData = {
      type: 'remove',
      heading: heading,
      msg: this.aemLabelAuthoring ? this.aemLabelAuthoring.form.alertBox.removeMsg : '',
      removeBtnAlert: this.aemLabelAuthoring ? this.aemLabelAuthoring.form.removeBtnAlert : 'Remove notification',
      cancel: this.aemLabelAuthoring ? this.aemLabelAuthoring.form.cancel : 'Cancel',
    };
    this.notificationService.setModalData(DATA);
    this.modalService.open(AlertBoxAdminNotificationPrefComponent).result.then((res) => {
      if (res && res === 'remove') {
        this.remove();
      }
    }).catch(err => {
    });
  }

  createForm() {
    this.addPreferenceForm = this.fb.group({
      businessName: [undefined, [Validators.required]],
      notificationType: [undefined, [Validators.required]],
      emailAddress: [undefined, [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      //notificationPreferences: [undefined, [Validators.required]],
    });
    if (this.editData) {
      let email, _notificationPref;
      //_notificationPref = this.editData.notificationPreference ? this.editData.notificationPreference.toLowerCase() : '';
      // email = (_notificationPref === 'all' || _notificationPref === 'notify for all updates' ? this.editData.notifymailId : _notificationPref === 'notify when resolved' ? this.editData.resolutionemailId : undefined);
      email = this.editData.assuranceresolved_email;
      this.emailAddressModel.inputText = email;
      this.addPreferenceForm = this.fb.group({
        businessName: [this.editData.businessName, [Validators.required]],
        notificationType: [this.editData.notificationtype, [Validators.required]],
        emailAddress: [email, [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
        //notificationPreferences: [this.editData.notificationPreference, [Validators.required]],
      });
      this.businessNameModel.isDisabled = true;
      this.update = true;
    } else {
      this.update = false;
      this.businessNameModel.isDisabled = false;
    }
  }

  setEditData() {
    if (this.addPreferenceForm.get('businessName')) {
      this.addPreferenceForm.get('businessName').setValue(this.editData.businessName);
    }
    if (this.addPreferenceForm.get('notificationType')) {
      this.addPreferenceForm.get('notificationType').setValue(this.editData.notificationtype);
    }
    // if (this.addPreferenceForm.get('notificationPreferences')) {
    //   this.addPreferenceForm.get('notificationPreferences').setValue(this.editData.notificationPreference);
    // }
    if (this.addPreferenceForm.get('emailAddress')) {
      let email, _notificationPref;
      //_notificationPref = this.editData.notificationPreference ? this.editData.notificationPreference.toLowerCase() : '';
      //email = (_notificationPref === 'all' || _notificationPref === 'notify for all updates' ? this.editData.notifymailId : _notificationPref === 'notify when resolved' ? this.editData.resolutionemailId : undefined);
      email = this.editData.assuranceresolved_email;
      this.addPreferenceForm.get('emailAddress').setValue(email);
      this.emailAddressModel.inputText = email;
      this.addPreferenceForm.get('emailAddress').valueChanges.subscribe(val => {
        this.emailAddressModel.inputText = val;
      });
    }
    this.businessNameModel.isDisabled = true;
  }

  async getLabels() {
    this.notificationService.getCompanyNotificationModel().then(async (data: AddPreferenceData) => {
      await data;
      this.aemLabelAuthoring = data;
      this.notificationTypeItems = this.aemLabelAuthoring.form.notificationTypeItems;
      this.notificationPreferenceItems = this.aemLabelAuthoring.form.notificationPreferenceItems;
      this.emailAddressModel.headerText = this.aemLabelAuthoring.form.emailAddress;
      this.emailAddressModel.placeholderCaption = this.aemLabelAuthoring.form.emailAddressPlaceholder;
      this.emailAddressModel.validationMessage = this.aemLabelAuthoring.form.emailAddressValidationMessage;
      this.emailAddressModel.requiredText = this.aemLabelAuthoring.form.emailAddressRequiredMessage;
      // this.getBusinessNames();
    }).catch((error) => {
    });
  }

  getBusinessNames() {
    //const params = { 'page': ' ' };
    const params = { 'infraco': 'false' };
    this.businessNames = [];
    this.usersService.getBusinessNamesMyUser(params).then(res => {
      this.businessRoleDetails = res;
      this.businessNames = this.businessRoleDetails.BusinessNameDetails;
      this.businessNames.forEach(element => {
        if(this.userRole.includes('ampliteladmin')){
          this.businessNameItems.push(element['Business name']);
        } else if(!this.userRole.includes('ampliteladmin')){
          if (this.cidns.indexOf(element['CIDN']) > -1) {
            this.businessNameItems.push(element['Business name']);
          }
        }        
      });
      this.businessNames.sort();
      if (this.businessNameItems.length > 1) {
        this.moreThanOneBusiness = true;
        if (this.addPreferenceForm.get('businessName')) {
          this.addPreferenceForm.get('businessName').setValidators([Validators.required]);
        }
      } else if (this.businessNameItems.length === 1 && this.addPreferenceForm.get('businessName')) {
        this.moreThanOneBusiness = false;
        this.addPreferenceForm.get('businessName').setValue(this.businessNameItems[0]);
      }
    }).catch((err) => {
      this.businessNames = [];
    });

  }

  onSubmit(): any {
    const DATA = this.addPreferenceForm.value;
    const businessName = DATA.businessName;
    const notificationType = DATA.notificationType ? DATA.notificationType : 'Billing';
    //const notificationType = DATA.notificationType ? DATA.notificationType === 'Assurance' ? 'Fault' : '' : '';
    DATA.notificationType = notificationType; 
    //DATA.loggedInEmail = this.loggedInEmail ? this.loggedInEmail : '';
    DATA.contactnumber = this.phoneNumber;
    let selectedCIDN;
    if (this.businessNames && this.businessNames.length) {
      this.businessNames.forEach(element => {
        if (element['Business name'] === businessName) {
          selectedCIDN = element['CIDN'];
        }
      });
    }
    if (DATA && selectedCIDN) {
      let customerlevel = 'Y' // Y for add preference
      this.notificationService.addPreference(DATA, selectedCIDN, this.update, customerlevel)
        .then((response) => {
          this.notificationService.addPreferenceFormData.data = this.addPreferenceForm.value;
          this.notificationService.addPreferenceFormData.page = this.update ? 'Modify' : 'Create';
          this.update = false;
          this.router.navigate(['companynotifications']);
        }).catch((err) => {
          let msg, _error: string;
          if (err && err.error && err.error.data && err.error.data.results && err.error.data.results.length) {
            _error = err.error.data.results[0] ? err.error.data.results[0].message ? err.error.data.results[0].message : undefined : undefined;
            _error = _error ? _error.toLowerCase() ? _error.toLowerCase() : undefined : undefined;
            if (_error && _error === 'unique constraint') {
              msg = 'Preference already present';
            } else if (_error && _error === 'user is not authorized') {
              msg = 'Not an Authorized User';
            } else {
              msg = 'Something went wrong';
            }
            this.showToasterMessage(msg, '', this.options, 'error');
          }
        });
    }
  }

  cancelCreate() {
    this.toastr.clear();
    if (this.update) {
      this.setEditData();
    } else {
      this.addPreferenceForm.reset();
    }
    this.addPreferenceForm.markAsUntouched();
    this.router.navigate(['companynotifications']);
  }

  remove() {
    const CIDN = this.editData.cidn ? this.editData.cidn : '';
    if (CIDN) {
      let params = { contactnumber: this.phoneNumber };
      this.notificationService.addPreference(params, CIDN, false, "Y", true).then(() => {
        this.notificationService.addPreferenceFormData.page = 'Delete';
        this.update = false;
        this.router.navigate(['companynotifications']);
      }).catch((err) => {
        const msg = err.error ? err.error.message ? err.error.message : 'Something went wrong' : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      });
    }
  }

  showToasterMessage(
    message: string,
    header: string,
    options: GlobalConfig,
    type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'add-preference';
    this.options.disableTimeOut = false;
    this.options.autoDismiss=true;
    this.options.timeOut=5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
  }

}