<div class="page-bg">
<div class="container-fluid page-container create-customer"
*ngIf="aemLabelAuthoring">
    <h1 class="page-header"id="customerCreate" >{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
    <div class="wrapper">
      <h2 class="create-customer-header" automationType="label" automationId="lblChoosePlan">
        {{aemLabelAuthoring.pageHeader.pageSubHeaderLabel}}
      </h2>
      <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
      <form [formGroup]="createCustomerForm" id="createCustomer" #createCustomerTicket="ngForm">
        <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
        <div class="form-group">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 userNameWidth">
              <app-cui-textfield [(model)]="businessNameModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div">
              <app-cui-textfield [(model)]="cidnModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="dropdown-contact-options col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <label class="statusLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="statusHeader"
                attr.aria-label="Staus">
                {{aemLabelAuthoring.form.statusModel}}
              </label>
              <app-cui-searchable-dropdown name="status" [formControlName]="'status'" [model]="statusModel"
                [labelName]="'status'" [placeholder]="'Select status'"
                [isRequired]=true [items]=statusItems automationType="dropdown" automationId="drpdwnroleType"
                [noAutoComplete]=false id="statusDropdown" class="user-dropDown"
                [ngClass]="{'invalid-form-control': (createCustomerForm.controls.status.invalid && createCustomerForm.controls.status.touched )}"
                required>
              </app-cui-searchable-dropdown>
              <img src="../../../assets/images/select-arrow.png" 
              class="dropdown-arrow" alt=""/>
              <div class="row ml-0" aria-live="assertive">
                <span role="alert"
                  *ngIf="createCustomerForm.controls.status.invalid && createCustomerForm.controls.status.touched"
                  class="help-block errorMsg">
                  {{aemLabelAuthoring.form.statusRequiredMessage}}
                </span>
              </div>
              <span class="asterisk_input"> </span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="abnModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
            <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
              <app-cui-textfield [(model)]="msoIdModel" [parentFormGroup]="createCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
          <div>
            <button type="submit" id="submit-btn" 
              tabindex="0"
              (click)="onSubmit()"
              class="red-primary-btn enquiry-submit-btn"
              [ngClass]="!createCustomerForm.valid? 'primary-btn-disabled':''" 
              [ngStyle]="{'cursor': createCustomerForm.valid === true ? 'pointer' : '' }" 
              [disabled]="!createCustomerForm.valid">
              {{aemLabelAuthoring.form.submit}}</button>
          </div>
          <div class="btn-div">
            <button class="red-secondary-btn"
            (click)="cancelCreate()"
            type="button" tabindex="0">{{aemLabelAuthoring.form.cancel}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>