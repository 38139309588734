import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { HamburgerMenuModule, FooterModule, HeaderModule, BannerModule, SpinnerModule, TwCoreUIFormModule, CanDeactivateGuard, AuthGuardService, AuthService } from 'tw-core-ui';
// import { CustomersRoutingModule } from './customers-routing.module';
import { CustomersComponent } from './customers.component';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from './customer.service';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { GridFilterModule } from '../grid-filter/grid-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { ModifyCustomerComponent } from './modify-customer/modify-customer.component';
import { SpinnerSVGComponent } from '../spinner/spinner.component';

@NgModule({
  declarations: [CustomersComponent, CreateCustomerComponent, CustomerListComponent, ModifyCustomerComponent, SpinnerSVGComponent],
  imports: [
    CommonModule,
  //  CustomersRoutingModule,
    TwCoreUIFormModule,
    ReactiveFormsModule,
    GridFilterModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    AgGridModule.withComponents([
      CustomTextFilterComponent,
      CheckboxFilterComponent,
      CustomDateFilterComponent
    ]),
  ],
  exports: [SpinnerSVGComponent],
  providers: [CustomerService, DatePipe, ToastrService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CustomersModule { }
