<div class="page-bg">
<div class="container-fluid page-container create-user searchInfracoAdmin" *ngIf="aemLabelAuthoring">
    <h1 class="page-header" id="createUserhead">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h1>
    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <div class="wrapper">
        <h2 class="create-myuser-header" automationType="label" 
          automationId="lblChoosePlan">
        {{aemLabelAuthoring.pageHeader.pageSubHeaderLabel}}
        </h2>
    <form [formGroup]="createUserForm" id="createUser" #createUserTicket="ngForm">
        <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
        <div class="form-group">
            <div class="row">
                <div class="col-xs-12 col-sm-11 col-md-9 col-lg-8 userNameWidth">
                    <app-cui-textfield [(model)]="userNameModel"
                    [parentFormGroup]="createUserForm"
                    [ngClass]="{'invalidUserName': userNameExistMessage}">
                    </app-cui-textfield>
                </div>
                <div class="col-xs-12 col-sm-3 col-md-3 col-lg-4 validateUser">
                  <button type="button" class="red-primary-btn" (click)="validateUser()">
                    {{aemLabelAuthoring.form.validate}}
                  </button>
                </div>
            </div>
        <div class="row ml-0 pl-0 col-xs-12 col-sm-8 col-md-8 col-lg-8" aria-live="assertive">
            <span role="alert" *ngIf="userNameExistMessage" class="help-block errorMsg">{{userNameExistMessage}}</span>
            <span role="alert" *ngIf="domainErrorMessage" class="help-block errorMsg">{{domainErrorMessage}}</span>
        </div>
            <div class="row create-user-details">
                 <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <app-cui-textfield [ngClass]="{'disabledropDown': firstNameModel.isDisabled}"
                    [(model)]="firstNameModel"
                    [parentFormGroup]="createUserForm">
                    </app-cui-textfield>
                </div>  
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                    <app-cui-textfield 
                    [(model)]="lastNameModel" [ngClass]="{'disabledropDown': lastNameModel.isDisabled}"
                    [parentFormGroup]="createUserForm">
                    </app-cui-textfield>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                        <app-cui-textfield 
                        [(model)]="phoneModel"
                        [parentFormGroup]="createUserForm" [ngClass]="{'disabledropDown': phoneModel.isDisabled}"
                        (blurred)="onBlurEvent()">
                        </app-cui-textfield>
                </div> 
            </div>
              <div aria-live="polite" role="alert">
                <p class="warning-msg" *ngIf="flag">
                    <i aria-hidden="true" class="warning-icon"></i>{{aemLabelAuthoring.form.phoneNumberAlert}}</p>
              </div>
        <table class="table" *ngIf ="rowData.length > 0">
            <caption class="sr-only">User role details</caption>
            <tr><th *ngFor="let col of tableCol">{{col}}</th></tr>
            <tr *ngFor="let row of rowData; let i = index">
                <td> {{row.business}} </td>
                <td> {{row.role}} </td>
                <td> <button type="button" tabindex="0"
                    class="secondary-btn xl-btn p-0 m-0 remove-btn"
                    (click)="removeRow(i)">
                    <span class="icon-icon-ui-cross" aria-hidden="true"></span>{{row.button}}
                </button> </td>
            </tr>
        </table>
        
        <div *ngIf="isVisible" class="addRole">
            <button type="button" id="addrole" class="secondary-btn addroleBtn m-0 p-0"  (click)="addRole()">
                <app-cui-svg-icon aria-hidden="true" [icon]="'icon-circlePlus'" class="svg-icon">
                </app-cui-svg-icon>
                {{aemLabelAuthoring.form.addRole}}
            </button>
        </div>
        <div class="row borderBox" *ngIf="!isVisible">
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4" id="bussiness">
                <label class="usertypeLbl checkLbl asteriskdropdown"
                   automationType="label"
                   automationId="lblbusinessName"
                   id="businessNameHeader"
                   role="button"
                   attr.aria-label= {{aemLabelAuthoring.form.businessType}}>
                   {{aemLabelAuthoring.form.businessType}}
                </label>
                <app-cui-searchable-dropdown #businessTypes
                    name="businessTypes"
                    [formControlName]="'businessTypes'"
                    [model]="businessNameDropDownModel"
                    [labelName]="aemLabelAuthoring.form.businessType"
                    [placeholder]="aemLabelAuthoring.form.businessPlaceHolder"
                    [isRequired]=true
                    [items]=businessNames
                    automationType="dropdown"
                    automationId="drpdwnbusinessType"
                    [noAutoComplete]=false
                    id="businessTypes"
                    class="user-dropDown"
                    (ngModelChange)='roleChange($event)'
                    [ngClass]="{'invalid-form-control': (createUserForm.controls.businessTypes.invalid && createUserForm.controls.businessTypes.touched)}"
                    required>
                </app-cui-searchable-dropdown>
                <div class="row ml-0" aria-live="assertive">
                    <span role="alert" *ngIf="createUserForm.controls.businessTypes.invalid && createUserForm.controls.businessTypes.touched" class="help-block errorMsg">
                       {{businessNameRequiredMsg}}
                    </span>
                </div>
                <span class="asterisk_input">  </span>
            </div>
            <!-- app name dropdown removed -->
            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-4">
                <label class="usertypeLbl checkLbl asteriskdropdown"
                   automationType="label" automationId="lblrole" role="button"
                   id="roleHeader" attr.aria-label= {{aemLabelAuthoring.form.role}}>
                   {{aemLabelAuthoring.form.role}}
                </label>
                <app-cui-searchable-dropdown
                    name="userRole"
                    [formControlName]="'userRole'"
                    [model]="roleDropDownModel"
                    [labelName]="aemLabelAuthoring.form.role"
                    [placeholder]="aemLabelAuthoring.form.rolePlaceholder"
                    [isRequired]=true
                    [items]=roleOptions
                    automationType="dropdown"
                    automationId="drpdwnroleType"
                    [noAutoComplete]=false
                    class="user-dropDown"
                    [ngClass]="{'invalid-form-control': (createUserForm.controls.userRole.invalid && createUserForm.controls.userRole.touched)}"
                    required>
                </app-cui-searchable-dropdown>
                <div class="row ml-0" aria-live="assertive">
                    <span role="alert" *ngIf="createUserForm.controls.userRole.invalid && createUserForm.controls.userRole.touched" class="help-block errorMsg">
                       {{roleRequiredMsg}}
                    </span>
                </div>
                <span class="asterisk_input">  </span>
            </div>
            <div class="button-container myuser-button-container col-xs-12 col-sm-6 col-md-4 col-lg-4">
                <button type="button" [disabled]="!isRequired" 
                    class="red-primary-btn xl-btn create-mobile-view-submit mr-3"
                    (click)="onSave()">{{aemLabelAuthoring.form.save}}
                </button>
                <button type="button" *ngIf = "rowData.length > 0"
                    class="red-secondary-btn create-mobile-view-cancel"
                    (click)="cancelRole()">{{aemLabelAuthoring.form.cancel}}
                </button>
            </div>
        </div>
        <div>
            <div class="button-container myuser-button-container">
                <button type="button" [disabled]="!isRequired"
                    class="red-primary-btn xl-btn mr-3"
                    (click)="onSubmit(createUserTicket)">{{aemLabelAuthoring.form.submit}}</button>
                <button type="button" class="red-secondary-btn"
                    (click)="cancelCreate()">{{aemLabelAuthoring.form.cancel}}
                </button>
            </div>
        </div>
    </div>
    </form>
  </div>
</div>
</div>