
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
// import { GridData } from '../../../shared/models/common-model';
// import { CreateUserData } from './users.model';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

@Injectable()
export class UsersService {
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private env: any = window['environment'];
  adminRoles = [];
  validUserRoles = [];
  private aemDefaultUri = {
    gridHeader: 'myuser-grid-header',
    gridData: 'users',
    newgridData:'portal-users',
    users: 'users/portal-users',
    gridLabels: 'myuser',
    createUser: 'create-user',
    getUser: 'myuser-data',
    phoneUpdate: 'users/profile/',
    deletePreference: 'customer-notification-preferences'
  };

  public gridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  public gridLabelAuthoringDataObj: any;
  //GridData;
  public createUserLabelAuthoringDataObj: any;
  // CreateUserData;
  payLoad: any;
  getPayload: any;
  createUserRoleObj: any = [];
  public userDataObj: any = [];
  public createuserFormData: any;
  public existingUserData: any;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.gridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params, userType) {
    this.payLoad = params || {
      'data': {}
    };
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && (!this.payLoad['data'].hasOwnProperty('fromLoggedDate')) && (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
      this.getPayload = '?id=' + this.payLoad['data'].id;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('fromLoggedDate') && (!this.payLoad['data'].hasOwnProperty('toLoggedDate'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && (!this.payLoad['data'].hasOwnProperty('fromLoggedDate'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('id') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && this.payLoad['data'].hasOwnProperty('fromLoggedDate')) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?id=' + this.payLoad['data'].id + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate + '&fromLoggedDate=' + this.payLoad['data'].fromLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (this.payLoad['data'].hasOwnProperty('fromLoggedDate') && this.payLoad['data'].hasOwnProperty('toLoggedDate') && (!this.payLoad['data'].hasOwnProperty('id'))) {
      // tslint:disable-next-line:max-line-length
      this.getPayload = '?fromLoggedDate=' + this.payLoad['data'].fromLoggedDate + '&toLoggedDate=' + this.payLoad['data'].toLoggedDate;
    }
    // tslint:disable-next-line:max-line-length
    if (!this.payLoad['data'].hasOwnProperty('id') && !this.payLoad['data'].hasOwnProperty('toLoggedDate') && !this.payLoad['data'].hasOwnProperty('fromLoggedDate')) {
      this.getPayload = false;
    }

    if (this.payLoad['data'].hasOwnProperty('roleList')) {
      this.payLoad = '?cidn=' + this.payLoad['data'].roleList.split(':')[0];
    } else {
      this.payLoad = '';
    }
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': tokenValue ? tokenValue : '',

      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<GridDataInterface>(this.env.apiPoint + this.aemDefaultUri.users + this.payLoad, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['wholesale-users'];
          resolve(true);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public getWorkListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabels;
        this.http.get<any>(worklistUrl)
          /*   .takeUntil(componentDestroyed(this)) */
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getCreateMyUserModel() {
    return new Promise((resolve, reject) => {
      if (!this.createUserLabelAuthoringDataObj) {
        const createuserUrl = this.env.aemEndPoint + this.aemDefaultUri.createUser;
        this.http.get<any>(createuserUrl)
          /*   .takeUntil(componentDestroyed(this)) */
          .subscribe(
            res => {
              this.createUserLabelAuthoringDataObj = res.data.content;
              resolve(this.createUserLabelAuthoringDataObj);
            },
            err => {
              this.createUserLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.createUserLabelAuthoringDataObj);
      }
    });
  }

  public createUser(params, roleDetails) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': params.userName,
        'name': {
          'givenName': params.firstName,
          'familyName': params.lastName
        },
        'phone': params.phoneNumber,
        'role': roleDetails
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + this.aemDefaultUri.users, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public getUserData(val, page?) {
    this.sendSpinnerFlag.next(true);
    //  this.getValidRoleDetails();
    if (page === 'profile' || page === 'enquiry') {
      const roles = this.getUserRoles();
      this.payLoad = val.userName ? '/profile/' + val.userName + `?src_screen=${val.src_screen}`: '';
    } else {
      this.payLoad = val.userName ? '/' + val.userName + `?src_screen=${val.src_screen}` : '';
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };

    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          if (err.status === 404) {
            resolve(err);
          } else {
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        });
    });
  }
  public getBusinessNamesMyUser(params) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = params ? '/db/allCustomer' : '/db';
   // this.payLoad = params && params.infraco ? '/db/allCustomer' : '/db';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res.data);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  public getBusinessNames(params) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = params && params.infraco ? '/db/allCustomer' : '/db';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + this.payLoad, httpOptions)
        .subscribe(res => {
          resolve(res.data);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getUserRoles() {
    let roles = [];
    let user_roles: any = this.authService.user ? this.authService.user.profile.roles : this.env.roles;
    if (Array.isArray(user_roles)) {
      user_roles.forEach(item => roles.push(item.value));
    } else {
      roles.push(user_roles.value);
    }
    return roles;
  }

  public getValidRoleDetails() {
    if (this.validUserRoles.length === 0) {
      return new Promise((resolve, reject) => {
        this.getAllRoles().then(res => {
          this.adminRoles = res['roles'];
          this.validUserRoles = [];
          const roles = this.getUserRoles();
          roles.forEach((role, index) => {
            if (this.adminRoles.indexOf(role.split(':')[1].toLowerCase()) > -1) {
              this.validUserRoles.push(role);
            }
          });
          resolve(true);
        }).catch((err) => {
          reject(false);
        });
      });
    } else {
      this.validUserRoles = this.validUserRoles;
    }
  }
  public modifyUser(params, userData) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': userData.userName,
        'operations': params
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.gridData + '/' + this.aemDefaultUri.newgridData + '/' + userData.id;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public modifyPhoneNumber(params, userData) {
    this.sendSpinnerFlag.next(true);
    this.payLoad = {
      'data': {
        'userName': userData.userName,
        'operations': params
      }
    };

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.phoneUpdate + userData.id;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, this.payLoad, httpOptions)
        .subscribe(res => {
          this.createUserRoleObj = res['data']['wholesale-users'];
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  public deleteUserPreference(params, userData) {
    this.sendSpinnerFlag.next(true);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      }),
      body: {
        'data': {
          'userName': userData.userName,
          'operations': params
        }
      },
    };
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(this.env.apiPoint + this.aemDefaultUri.deletePreference, options)
        .subscribe(res => {
          resolve(res);
          this.sendSpinnerFlag.next(false);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  // public getAdminRoles() {
  //   return new Promise((resolve, reject) => {
  //     this.http.get<GridHeaderInterface>(this.env.aemEndPoint + 'role-list')
  //       .subscribe(res => {
  //         resolve(res['data']);
  //       }, err => {
  //         reject(err);
  //         return observableThrowError(err || 'Server error');
  //       });
  //   });
  // }
  public getAllRoles() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + 'role-list')
        .subscribe(res => {
          resolve(res['data']);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

}
