
import { throwError as observableThrowError, Observable, BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';

@Injectable()
export class CommonService {
  private env: any = window['environment'];

  primaryBusinessName = new BehaviorSubject({businessName: '', cidn:  '', abn: '', msoId: '', roles: [], isSetBusiness: false});

  navigationListData = [];
  functionDetailsListData: any;

  setTermsAndCondSubject = new Subject<boolean>();

  constructor(private http: HttpClient, private authService: AuthService) { }
  Modalsubject = new BehaviorSubject('');
  userData = [];
  activeIdd: string = '';
  

  isAmplitelAdmin = false;
  allBusinessCIDNListBasedOnRole =[];
  amplitelAdminList = ['ampliteladmin', 'ampliteloperation'];

  public getroleDetails() {
    const roleList = [];
    const appList = [];
    const cidnList = [];
    const adminCidns = [];
    const user_roles = this.env.production ? this.authService.user?.profile?.roles : this.env.roles;
    
    if (user_roles) {
      if (Array.isArray(user_roles)) {
        user_roles.forEach(item => {
          const tempApp = item.type.split(':')[1];
          const tempRole = item.value.split(':')[1];
          const tempCidn = item.value.split(':')[0];
          if (tempApp) {
            appList.push(tempApp);
          }
          if (tempRole) {
            if(this.amplitelAdminList.includes(tempRole))
              adminCidns.push(tempCidn);
            roleList.push(tempRole);
          }
          if (tempCidn) {
            cidnList.push(tempCidn);
          }
        });
      } else {
          const tempApp = user_roles.type.split(':')[1];
          const tempRole = user_roles.value.split(':')[1];
          const tempCidn = user_roles.value.split(':')[0];
          if (tempApp) {
            appList.push(tempApp);
          }
          if (tempRole) {
            if(this.amplitelAdminList.includes(tempRole))
              adminCidns.push(tempCidn);
            roleList.push(tempRole);
          }
          if (tempCidn) {
            cidnList.push(tempCidn);
          }
      }
    }
    return { 'cidnList': cidnList, 'roleList': roleList, 'appList': appList, 'adminCidns': adminCidns };
  }

  public getCustomerStatus(params) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = '';
    // SIT
    // url =  'https://teamspeed-whls-whlsale-omp.sv.telstra-cave.com/presentation/v1/';

    //Non SIT
    url = this.env.apiPoint;
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url + 'users/customer/status?cidnList=' + params.cidnList, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getRoleValues() {
    const roles = [];
    const user_roles: any = this.authService.user.profile.roles;
    if (Array.isArray(user_roles)) {
      user_roles.forEach(item => roles.push(item.value));
    } else {
      roles.push(user_roles.value);
    }
    return roles;
  }

  public getMenuAccessData() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = '';
    // SIT
    // url =  'https://teamspeed-whls-whlsale-omp.sv.telstra-cave.com/presentation/v1/';

    //Non SIT
    url = this.env.apiPoint;
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url + 'wholesale-tickets/functionDetails', httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  updateModal(data){
    this.Modalsubject.next(data);
  }

  setUserBusinessNames(data:any){
    this.userData =data;
  }

  getUserBusinessNames(){
    return this.userData;
  }
  

  public getAllBusinessNames() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    const url = '/db/allCustomer';
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + 'users' + url, httpOptions)
        .subscribe(res => {
          const businessNames = res.data?.BusinessNameDetails ? res.data.BusinessNameDetails : [];
          resolve(businessNames);
        }, err => {
          reject(err);
        });
    });
  }

  

  public savePrimaryBusiness(selectedBusiness, selectedCidn, contactEmail, isModified) {
    const url = this.env.apiPoint + 'users' + '/primary-customer';
    const payload = {
      "data": {
        "userName": contactEmail,
        "businessName": selectedBusiness,
        "cidn": selectedCidn[0],
        "modifyPrimaryCustomer": isModified
      }
    }
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    return new Promise((resolve, reject) => {
      this.http.post<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }


  getPrimaryValue(){
    return this.primaryBusinessName.getValue();
  }

  
  setAdminAndBusiness() {
    const user_roles = this.env.production ? this.authService.user.profile.roles : this.env.roles;

    // { "type": "cidn:tower-portal", "value": "10013:enquiryonlyuser" },
    const roles = [];
    (user_roles || []).forEach((ele) => {
      const roleCidn = ele.value.split(':')[0];
      const roleValue = ele.value.split(':')[1];

      if (this.amplitelAdminList.includes(roleValue)) {
        this.isAmplitelAdmin = true;
      }
      this.allBusinessCIDNListBasedOnRole.push(roleCidn);

    })

  }


  updateValue(newValue) {
    const user_roles = this.env.production ? this.authService.user.profile.roles : this.env.roles;

    const roles = [];
    (user_roles || []).forEach((ele) => {
      const roleCidn = ele.value.split(':')[0];
      if (roleCidn == newValue.cidn) {
        if (roles.indexOf(ele.value.split(':')[1]) === -1) {
          roles.push(ele.value.split(':')[1]);
        }
      }

    })

    if(newValue){

        this.primaryBusinessName.next({...newValue, roles: roles, isSetBusiness: true});
    }

  }

  updatePrimaryBusinessNameRoleForAmplitelAdmin() {
    const currentPrimaryBusinessName = this.getPrimaryValue();
    const user_roles = this.env.production ? this.authService.user.profile.roles : this.env.roles;

    const roles = [];
    (user_roles || []).forEach((ele) => {
        if (roles.indexOf(ele.value.split(':')[1]) === -1) {
          roles.push(ele.value.split(':')[1]);
        }
    })
    this.primaryBusinessName.next({...currentPrimaryBusinessName, roles: roles, isSetBusiness: true});

  }


  
  public getNavigationList() {
    return new Promise((resolve, reject) => {
      if (this.navigationListData.length == 0) {
        const worklistUrl = this.env.aemEndPoint + 'navigation';
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.navigationListData = res.data;
              resolve(this.navigationListData);
            },
            err => {
              this.navigationListData = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.navigationListData);
      }
    });
  }
  
  public getFunctionDetailsList() {
    return new Promise((resolve, reject) => {
      if (!this.functionDetailsListData) {
        const worklistUrl = this.env.aemEndPoint + 'function-details';
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.functionDetailsListData = res;
              resolve(this.functionDetailsListData);
            },
            err => {
              this.functionDetailsListData = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.functionDetailsListData);
      }
    });
  }

  userReport(action:'login'|'logout') {
    let cidn = this.getPrimaryValue().cidn;
    if(!cidn) cidn = this.getroleDetails().adminCidns.length?this.getroleDetails().adminCidns[0]:null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = 'users/user-login-report';
    let body : {'action': 'login'|'logout','cidn':string,'uniqueId':string} = {
      'action': action,  
      'cidn': cidn,
      'uniqueId':''
    }
    if(action=='logout') {
      body.uniqueId = sessionStorage.getItem('uniqueId');
    }
    return new Promise((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + url, body, httpOptions)
        .subscribe(res => {
          if(action=='login') sessionStorage.setItem('uniqueId',res.data.uniqueId);
          resolve(res.data);
        }, err => {
          reject(err);
        });
    });
  }


}