import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../users.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators, FormControl } from '@angular/forms';
import { CustomTextField, CustomDropDown, TermsCondtion } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { TwCoreUIUtilService } from 'tw-core-ui';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { UtilityService } from '../../services/utility.service';
import { CommonService } from 'src/app/services/common.service';
import { LoadingServiceService } from 'src/app/services/loading-service.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-modifyuser',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.less', '../create-user/create-user.component.less']
})
export class ModifyUserComponent implements OnInit {

  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: any;
  //CreateUserData;
  public modifyUserForm: FormGroup;
  public AppNameOptions = [];
  public roleOptions = [];
  public businessNames: any = [];
  userTypeMessage = '';
  domainErrorMessage = '';
  public tableCol: Array<string> = [];
  public rowData = [];
  public businessRoleData: any;
  public isVisible: boolean = false;
  businessNameRequiredMsg: string = '';
  roleRequiredMsg: string = '';
  public selectedCIDN: string = '';
  public newUserName: string;
  public createUserData: any;
  public removedRowData = [];
  public existingRoleData = [];
  public newRowData = [];
  public operations: Array<Object> = [];
  public selectedUser: string;
  billingRoles = [];
  orderingRoles = [];
  billingOrderingRoles = [];
  assuranceRoles = [];
  chatRoles = [];
  billAdminRoles =[];
  infracoRoles = [];
  existingUserAlert: Boolean = true;
  flag: boolean;
  public userNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyUserName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxuserName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'userName',
    tabindex: '-1',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'userName',
    formControlName: 'userName',
    isDisabled: true,
    showValidationIcon: true
  };
  public firstNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyfirstName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxfirstName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divfirstNameValidationErrMsg',
    placeholderCaption: '',
    id: 'firstName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'firstName',
    formControlName: 'firstName',
    isDisabled: false,
    showValidationIcon: false
  };
  public lastNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMylastName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxlastName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divlastNameValidationErrMsg',
    placeholderCaption: '',
    id: 'lastName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'lastName',
    formControlName: 'lastName',
    isDisabled: false,
    showValidationIcon: false
  };
  public phoneModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'phoneNumber',
    tabindex: '',
    maxlength: '10',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'phoneNumber',
    formControlName: 'phoneNumber',
    isDisabled: false,
    showValidationIcon: false
  };
  public appNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'appNameHeader'
  };
  public roleDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: "Role Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under"
  };
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  popUpCanceldataSource: TermsCondtion;
  options: GlobalConfig;
  type = ['success', 'error', 'info', 'warning'];

  public infracoAdmin: boolean = false;

  constructor(
    private dataSvc: UsersService,
    private fb: FormBuilder,
    private loader: LoadingServiceService,
    private util: TwCoreUIUtilService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private router: Router,
    private commonService: CommonService,
    private titleService: Title,
    private sharedService: SharedService
  ) {
    this.titleService.setTitle('Amplitel Modify a User');
    this.options = this.toastr.toastrConfig;
    this.getRoles();
    this.createUserData = this.dataSvc.existingUserData;
  }

  getRoles() {
    const roles = (this.commonService.getroleDetails()).roleList;
    return new Promise((resolve, reject) => {
      this.dataSvc.getAllRoles().then(res => {
        if (roles.includes('customeradmin') &&
          !roles.includes('ampliteladmin')) {
          this.roleOptions = res['customeradminRoles'];
        } else if (roles.includes('ampliteladmin')) {
          this.roleOptions = res['roles'];
        }
        this.getLabels();
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getBusinessNames(null).then(res => {
        this.businessRoleData = res;
        if (this.businessRoleData.BusinessNameDetails.length > 0) {
          this.businessRoleData.BusinessNameDetails.forEach(entry => {
            if (Object.keys(entry).length > 0) {
              this.businessNames.push(entry['Business name'].trim());
            }
          });
          if (this.businessNames.length > 0) {
            this.dataSvc.getCreateMyUserModel().then(() => {
              this.aemLabelAuthoring = this.dataSvc.createUserLabelAuthoringDataObj;
              this.userNameModel.headerText = this.aemLabelAuthoring.form.userName;
              this.userNameModel.placeholderCaption = this.aemLabelAuthoring.form.userNamePlaceholder;
              this.firstNameModel.headerText = this.aemLabelAuthoring.form.firstName;
              this.firstNameModel.placeholderCaption = this.aemLabelAuthoring.form.firstNamePlaceholder;
              this.lastNameModel.headerText = this.aemLabelAuthoring.form.lastName;
              this.lastNameModel.placeholderCaption = this.aemLabelAuthoring.form.lastNamePlaceholder;
              this.userNameModel.validationMessage = this.aemLabelAuthoring.form.userNameValidationMessage;
              this.popUpCanceldataSource = this.aemLabelAuthoring.form.popUpCanceldataSource;
              this.userNameModel.requiredText = this.aemLabelAuthoring.form.userNameRequiredMessage;
              this.firstNameModel.validationMessage = this.aemLabelAuthoring.form.firstNameValidationMessage;
              this.lastNameModel.validationMessage = this.aemLabelAuthoring.form.lastNameValidationMessage;
              this.firstNameModel.requiredText = this.aemLabelAuthoring.form.firstNameRequiredMessage;
              this.lastNameModel.requiredText = this.aemLabelAuthoring.form.lastNameRequiredMessage;
              this.userTypeMessage = this.aemLabelAuthoring.form.userTypeRequiredMessage;
              this.phoneModel.headerText = this.aemLabelAuthoring.form.phoneNumber;
              this.phoneModel.placeholderCaption = this.aemLabelAuthoring.form.phonePlaceholder;
              this.phoneModel.validationMessage = this.aemLabelAuthoring.form.phoneNumberValidationMessage;
              this.phoneModel.requiredText = this.aemLabelAuthoring.form.phoneNumberRequiredMessage;
              this.businessNameRequiredMsg = this.aemLabelAuthoring.form.businessNameRequiredMessage;
              this.roleRequiredMsg = this.aemLabelAuthoring.form.roleRequiredMessage;
              this.AppNameOptions = this.aemLabelAuthoring.form.userTypeDataOptions;
              this.userTypeMessage = this.aemLabelAuthoring.form.userTypeRequiredMessage;
              this.billingRoles = this.aemLabelAuthoring.form.billingRoles;
              this.orderingRoles = this.aemLabelAuthoring.form.orderingRoles;
              this.assuranceRoles = this.aemLabelAuthoring.form.assuranceRoles;
              this.chatRoles = this.aemLabelAuthoring.form.chatRoles;
              this.billAdminRoles = this.aemLabelAuthoring.form.billAdminRoles;
              this.businessNames = this.businessNames.sort();
              this.isVisible = true;
              if (this.createUserData) {
                this.tableCol = this.aemLabelAuthoring.form.businessTableHeader;
                const loggedUserRoles = this.dataSvc.getUserRoles();
                const loggedUserValues = [];
                const loggedUserCidns = [];
                loggedUserRoles.forEach(data => {
                  loggedUserCidns.push(data.split(':')[0]);
                  loggedUserValues.push(data.split(':')[1]);
                });
               // if (loggedUserValues.some(value => this.infracoRoles.indexOf(value.toLowerCase()) > -1)) {
                  this.createUserData.roles.forEach(val => {
                    this.existingRoleData.push(val);
                    this.rowData.push({
                      'business': val.businessName,
                      //'app': val.appName.toUpperCase(),
                      'role': val.value, 'button': 'Remove'
                    });
                  });
                // } else {
                //   this.createInitialRowData(loggedUserCidns);
                // }
              }
              resolve(true);
            }).catch(() => {
              reject(false);
            });
          } else {
            this.router.navigateByUrl('/error');
          }
        } else {
          this.router.navigateByUrl('/error');
        }
      }).catch((err) => {
      });
    });
  }

  createInitialRowData(cidns) {
    this.createUserData.roles.forEach(val => {
      if (cidns.indexOf(val.cidn) > -1) {
        this.existingRoleData.push(val);
        this.rowData.push({
          'business': val.businessName,
          // 'app': val.appName.toUpperCase(),
          'role': val.value, 'button': 'Remove'
        });
      }
    });
  }
  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    if (this.createUserData) {
      this.userNameModel.inputText = this.createUserData.userName;
      this.firstNameModel.inputText = this.createUserData.name.givenName;
      this.lastNameModel.inputText = this.createUserData.name.familyName;
      this.phoneModel.inputText = this.createUserData.phoneNumbers[0].value;
      this.modifyUserForm = this.fb.group({
        userName: [this.userNameModel.inputText],
        firstName: [this.firstNameModel.inputText, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
        lastName: [this.lastNameModel.inputText, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
        phoneNumber: [this.phoneModel.inputText, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]],
        businessTypes: [''],
        AppName: ['TowerCo'],
        userRole: ['']
      });
      this.dataSvc.createuserFormData = '';
      this.modifyUserForm.get('userName').valueChanges.subscribe(val => {
        this.domainErrorMessage = '';
      });
      this.modifyUserForm.valueChanges.subscribe(val => {
        this.toastr.clear();
      });
      setTimeout(() => {
        this.existingUserAlert = false;
      }, 3000);
    } else {
      this.router.navigate(['manageUsers/createUser']);
    }
  }

  closeNotification() {
    this.existingUserAlert = false;
  }
  cancelCreate() {
    this.router.navigate(['manageUsers/myUser']);
  }

  cancelRole() {
    this.isVisible = true;
    this.resetFormData();
    this.sharedService.focusOnElement('addrole');
  }

  onSubmit(createUserTicket: any): any {
    const roles = [];
    let userRoles = this.dataSvc.getUserRoles();
    if (userRoles && userRoles.length) {
      userRoles.forEach(item => {
        roles.push(item.split(':')[1].toLowerCase());
      });
      //   this.infracoAdmin = roles.some(val => this.infracoRoles.indexOf(val) > -1) ? true : false;
    }

    const userType = { 'infraco': this.infracoAdmin };

    if (this.modifyUserForm.valid) {
      this.loader.setLoader(true);
      if (this.rowData.length > 0) {
        // this.showToasterMessage(this.aemLabelAuthoring.form.emptyFieldsMessage, '', this.options, 'error');
      // } else {
        this.requestData();
        if (this.operations && this.operations.length === 0) {
          this.loader.setLoader(false);
          this.router.navigate(['manageUsers/myUser']);
        } else {
          let removeData = [];
          this.operations.forEach(item => {
            if (item['opValue'] && item['opValue'] === 'remove') {
              removeData.push(item);
            }
          });
          let errMsg:string = 'Unable to create/update the user in Enquiry System, please contact Level-1 support team';
          this.dataSvc.modifyUser(this.operations, this.createUserData).then(res => {
            this.dataSvc.createuserFormData = this.modifyUserForm.value;
            this.dataSvc.createuserFormData['page'] = 'Modify';
            this.dataSvc.existingUserData = '';
            if (removeData && removeData.length) {
              this.dataSvc.deleteUserPreference(removeData, this.createUserData).then(result => {
                this.loader.setLoader(false);
                this.router.navigate(['manageUsers/myUser']);
              }).catch((error) => {
                this.loader.setLoader(false);
                this.router.navigate(['manageUsers/myUser']);
              });
            } else {
              this.loader.setLoader(false);
              this.router.navigate(['manageUsers/myUser']);
            }
          }).catch((err) => {
            if(err.error.status === 500 && err.error.message.toLowerCase() === errMsg.toLowerCase()){              
              this.showToasterMessage(this.aemLabelAuthoring.form.errMsg, '', this.options, 'error');   
            }
            else if (err.error.status === 401 && err.error.message.toLowerCase() === 'userdomain is not authorized') {
              this.domainErrorMessage = this.aemLabelAuthoring.form.domainErrorMessage;
            } else {
              this.showToasterMessage(this.aemLabelAuthoring.form.createFailedMessage, '', this.options, 'error');
              this.util.bringFocusToElement(window.event, 'toast-close-button', false);
              setTimeout(() => (document.getElementById('toast-container').
                getElementsByClassName('toast-close-button')[0] as HTMLElement).focus(), 0);
            }
            setTimeout(() => {
              this.loader.setLoader(false);              
            }, 5000);
          });
        }
      } else {
        this.loader.setLoader(false);
        this.showToasterMessage(this.aemLabelAuthoring.form.roleAddMessage, '', this.options, 'error');
      }
    } else {
      this.validateCreateUser(this.modifyUserForm);
    }

  }

  validateCreateUser(formGroup: FormGroup) {
    const invalidControls = [];
    let control: any;
    const formControls = (form: FormGroup) => {
      Object.keys(form.controls).forEach(field => {
        control = form.get(field);
        if (control.invalid) {
          invalidControls.push(field);
          this.utility.scrollToElement(invalidControls[0]);
        }
      });
    };
    formControls(formGroup);
    invalidControls.forEach(invalidField => {
      const fieldName = <string>invalidField;
      formGroup.controls[fieldName].markAsTouched();
      invalidField = formGroup.controls[fieldName].invalid;
    });
    return invalidControls;
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss=true;
    this.options.timeOut=5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }

  onSave(): any {
    if (this.modifyUserForm.valid) {
      this.saveData();
    } else {
      this.validateCreateUser(this.modifyUserForm);
    }
    this.sharedService.focusOnElement('addrole');
  }

  roleChange(businessType) {
    // this.populateRole(businessType, this.modifyUserForm.value.AppName);
  }

  userSelect(app) {
    //  this.populateRole(this.modifyUserForm.value.businessTypes, app);
    this.selectedUser = app;
  }

  // populateRole(businessType, app) {
  //   const businessNames = [];
  //   const adrRoles = [];
  //   this.rowData.forEach(val => {
  //     if (val.app.toLowerCase() === 'adr') {
  //       adrRoles.push(val.role);
  //       businessNames.push(val.business);
  //     }
  //   });
  //   if (this.businessRoleData.RoleDetails.length > 0) {
  //     this.roleOptions = [];
  //     let roleTypes: Array<any> = this.businessRoleData.RoleDetails;
  //     let tempRoles = [];
  //     roleTypes = roleTypes.filter(key => {
  //       return Object.keys(key).indexOf(businessType) > -1;
  //     }).map(key => {
  //       if (app === 'TW-HUB') {
  //         return key[businessType].hubEntitlement;
  //       } else if (app === 'ADR') {
  //         let roleIndex;
  //         adrRoles.some(value => {
  //           roleIndex = key[businessType].adrEntitlement.split(',').indexOf(value);
  //           return roleIndex > -1;
  //         });
  //         if (roleIndex > -1 && businessNames.some(value => businessType === value)) {
  //           this.showToasterMessage(this.aemLabelAuthoring.form.singleAdrRoleMessage
  //             , '', this.options, 'error');
  //         } else {
  //           return key[businessType].adrEntitlement;
  //         }
  //       }
  //     });
  //     tempRoles = roleTypes[0] ? roleTypes.join().split(',') : [];
  //     tempRoles.forEach(val => {
  //       if (val.toLowerCase() !== 'null' && this.roleOptions.indexOf(val) === -1) {
  //         this.roleOptions.push(val);
  //       }
  //     });
  //     this.roleOptions = this.roleOptions.sort();
  //   } else {
  //     this.router.navigateByUrl('/error');
  //   }
  // }

  resetFormData() {
    if (this.isVisible) {
      this.modifyUserForm = new FormGroup({
        userName: new FormControl(this.modifyUserForm.value.userName),
        firstName: new FormControl(this.modifyUserForm.value.firstName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        lastName: new FormControl(this.modifyUserForm.value.lastName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        phoneNumber: new FormControl(this.modifyUserForm.value.phoneNumber, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())])
      });
    } else {
      this.modifyUserForm = new FormGroup({
        userName: new FormControl(this.modifyUserForm.value.userName),
        firstName: new FormControl(this.modifyUserForm.value.firstName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        lastName: new FormControl(this.modifyUserForm.value.lastName, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]),
        phoneNumber: new FormControl(this.modifyUserForm.value.phoneNumber, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]),
        businessTypes: new FormControl(''),
        AppName: new FormControl(''),
        userRole: new FormControl('')
      });
    }
  }

  saveData() {
    const businessDetails: Array<Object> = this.businessRoleData.BusinessNameDetails;
    this.selectedCIDN = this.modifyUserForm.value.businessTypes;
    this.firstNameModel.isDisabled = false;
    this.lastNameModel.isDisabled = false;
    this.phoneModel.isDisabled = false;
    this.isVisible = true;
    this.tableCol = this.aemLabelAuthoring.form.businessTableHeader;
    this.billingOrderingRoles = this.billingRoles.concat(this.orderingRoles);
    if (this.rowData.length > 0) {
      if (!this.rowData.some(value => value.role === this.modifyUserForm.value.userRole
        && value.business === this.modifyUserForm.value.businessTypes)) {
        if (this.rowData.some(val => this.billingRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.billingRoles.indexOf(this.modifyUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.modifyUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.billingRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.orderingRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.orderingRoles.indexOf(this.modifyUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.modifyUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.orderingRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.assuranceRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.chatRoles.indexOf(this.modifyUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.modifyUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.assuranceRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.chatRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.assuranceRoles.indexOf(this.modifyUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.modifyUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.chatRoleMsg
            , '', this.options, 'error');
        } else if (this.rowData.some(val => this.billAdminRoles.indexOf(val.role.toLowerCase()) > -1 &&
          this.billAdminRoles.indexOf(this.modifyUserForm.value.userRole.toLowerCase()) > -1 &&
          val.business === this.modifyUserForm.value.businessTypes)) {
          this.showToasterMessage(this.aemLabelAuthoring.form.assuranceRoleMsg
        , '', this.options, 'error');
        } else {
          this.addRow();
        }
      } else {
        this.showToasterMessage(this.aemLabelAuthoring.form.multipleRoleMessage
          , '', this.options, 'error');
      }
    } else {
      this.addRow();
    }
    this.selectedCIDN = (businessDetails.find((businessDetails) => businessDetails['Business name'].trim() === this.selectedCIDN))['CIDN'];
    this.resetFormData();
  }

  addRow() {
    this.rowData.push({ 'business': this.modifyUserForm.value.businessTypes, 'role': this.modifyUserForm.value.userRole, 'button': 'Remove', 'app': this.modifyUserForm.value.AppName });
  }

  addRole() {
    let addfocusableElement;
    this.isVisible = false;
    if (this.rowData.length === 0) {
      this.selectedUser = '';
    } else {
      // this.businessNameDropDownModel.isDisabled = true;
      // this.rowData.every(val => {
      //   if (val.app.toLowerCase() === 'adr') {
      //     this.selectedUser = 'ADR';
      //     return false;
      //   } else if (val.app.toLowerCase() === 'tw-hub') {
      //     this.selectedUser = 'TW-HUB';
      //     return false;
      //   }
      // });          
    }
    this.resetFormData();
    // this.modifyUserForm.controls['businessTypes'].setValue(this.rowData[0].business);
    if (this.businessNames.length <= 1) {
      // this.modifyUserForm.controls['businessTypes'].setValue(this.businessNames[0]);
    }
  }

  removeRow(index) {
    this.rowData.splice(index, 1);
    if (this.rowData.length === 0) {
      //this.appNameDropDownModel.isDisabled = false;
      this.businessNameDropDownModel.isDisabled = false;
    }
    if (!this.isVisible) {
      //     this.populateRole(this.modifyUserForm.value.businessTypes, this.modifyUserForm.value.AppName);
    }
  }

  requestData() {
    const businessDetails: Array<Object> = this.businessRoleData.BusinessNameDetails;
    this.operations = [];
    this.removedRowData = [];
    this.newRowData = [];
    this.rowData.forEach(val => {
      if (!this.existingRoleData.some(temp => temp.value === val.role && temp.businessName === val.business)) {
        this.newRowData.push({
          'business': val.business, 'role': val.role,
          'cidn': (businessDetails.find((businessDetails) => businessDetails['Business name'].trim() === val.business))['CIDN']
        });
      }
    });
    this.existingRoleData.forEach((val) => {
      if (!(this.rowData.some(temp => temp.role === val.value && temp.business === val.businessName))) {
        this.removedRowData.push(val);
      }
    });
    if (this.createUserData.name.givenName !== this.modifyUserForm.value.firstName ||
      this.createUserData.name.familyName !== this.modifyUserForm.value.lastName ||
      this.createUserData.phoneNumbers[0].value !== this.modifyUserForm.value.phoneNumber) {
      this.operations.push({
        'opValue': 'replace',
        'name': { 'givenName': this.modifyUserForm.value.firstName, 'familyName': this.modifyUserForm.value.lastName },
        'phone': this.modifyUserForm.value.phoneNumber
      });
    }
    if (this.removedRowData.length > 0) {
      this.removedRowData.forEach(val => {
        this.operations.push({
          'opValue': 'remove',
          'cidn': val.cidn,
          'Business name': val.businessName,
          'role': val.value
        });
      });
    }
    if (this.newRowData.length > 0) {
      const role = [];
      this.newRowData.forEach(val => {
        role.push({ 'roleValue': val.role, 'CIDN': val.cidn });
      });
      this.operations.push({ 'opValue': 'add', 'role': role });
    }
  }
  onBlurEvent() {
    this.flag = true;
  }
}
