import { Component, OnInit,ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { CustomAgGrid, CustomDropDown, CustomSearchBox, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { WorklistService } from '../services/worklist.service';
//import { CustomSearchBox } from 'src/app/shared/tw-core-ui-form/tw-core-ui-form.model';
import { forkJoin } from 'rxjs';
import * as moment_ from 'moment-timezone';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { Title } from '@angular/platform-browser';
import { UsersService } from 'src/app/users/users.service';
import { TicketSummaryService } from '../services/ticket-summary.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.less']
})
export class WorklistComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  private searchvalue;
  private env: Object = window['environment'];
  public gridConfig: GridOptions;
  createTicketObj: any;
  public filteredRowData: any = [];
  rowData = [];
  private columnDefs;
  options: GlobalConfig;
  currentMoment = moment_;
  subscribedflag: Boolean = false;
  subscription: any;
  gridInitialized: boolean;
  disableBussiness: boolean = false;
  // currentCidn = sessionStorage.getItem('cidn') ? sessionStorage.getItem('cidn') : '';
  currentCidn: any;
  public isClosedTicket: Boolean = false;
  //isChecked=true;
  selectedTimeZone: string = "Australia/Victoria";
  public searchDetails: any;
  public searchOn: Boolean = false;
  public downloadSummaryTooltipContentModel: TooltipContent = {
    content: '',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  public searchModel: CustomSearchBox = {
    containerId: 'search-row',
    headerId: 'search-ticket-date-row',
    searchId: 'searchIdInput',
    searchButtonId: 'searchButton',
    isResetVisible: false,
    isSearchDisabled: true,
    isSearchOn: false,
    isDateSearchOn: true,
    labelAuthoring: {
      header: 'Search ticket ID',
      searchPlaceholder: 'Search ticket ID',
      searchTextAccessible: '',
      fromDateAccessible: '',
      toDateAccessible: '',
      searchByDate: '',
      search: 'Search',
      reset: 'Reset'
    },
  };
  public aemLabelAuthoring: any;
  public dashboardCardFilter = {
    cidn: '',
    status: ''
  };
  public gridModel: CustomAgGrid = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public cidns: any = [];
  public businessNameItems = [];
  userRole: any[];
  businessRoleData: any;
  businessNames: string[];
  selectedBusinessName: any;
  public businessNameModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness Name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  hideDropdown: boolean = true;
  disableButton: boolean = true;
  public isSearched: boolean = false;

  
  isAmplitelAdmin = false;
  allBusinessCIDNListBasedOnRole = [];
  userRolePrimaryDetails: any;

  constructor(private wrkListSvc: WorklistService, private commonService: CommonService,
    private router: Router,
    private dataSvc: UsersService,
    private titleService: Title,
    private toastr: ToastrService,
    private ticketSummaryService: TicketSummaryService) {
    this.titleService.setTitle('My Enquiries');
    this.options = this.toastr.toastrConfig;
    this.userRole = (this.commonService.getroleDetails()).roleList;

    if (this.commonService.isAmplitelAdmin) {
      this.isAmplitelAdmin = true;
      this.allBusinessCIDNListBasedOnRole = this.commonService.allBusinessCIDNListBasedOnRole;
    } else {
      this.isAmplitelAdmin = false;
      this.allBusinessCIDNListBasedOnRole = [];
      this.userRolePrimaryDetails = this.commonService.getPrimaryValue();
      this.currentCidn = this.userRolePrimaryDetails.cidn;
    }
    this.getLabels();
  }

  ngOnInit(): void {
    //this.refreshTickets();
    this.isClosedTicket = false;
  }

  refreshTickets() {
    this.rowData = [];
    const params = {
      'data': {
        //'businessName': this.selectedBusinessName,
        'cidn': this.currentCidn,
        'isClosedTicket': this.isClosedTicket
      }
    };
    this.createGrid(params);
  }
  createGrid(payload) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      // this.router.navigateByUrl('/error');
    });
  }

  getBusinessNames() {
    const params = { 'infraco': 'false' };
    this.dataSvc.getBusinessNamesMyUser(params).then(res => {
      this.businessRoleData = res;
      this.businessNames = this.businessRoleData["BusinessNameDetails"];
      const currentCidns = this.commonService.allBusinessCIDNListBasedOnRole;
      this.businessNames.forEach((element: any) => {
        // if (currentCidns.includes(element.CIDN)) {
          this.businessNameItems.push(element['Business name']);
        // }
        
      });
      this.hideDropdown = false;
    }).catch((err) => {
      this.businessNames = [];
      this.router.navigateByUrl('/error');
    });
  }

  modelValueChange(event) {
    this.selectedBusinessName = event;
    this.disableButton = false;
  }

  getGridData() {
    this.gridInitialized = false;
    //this.isSearched = true;
    // let cidn;
    this.businessNames.forEach(element => {
      if (element['Business name'] === this.selectedBusinessName) {
        this.currentCidn = element['CIDN'];
      }
    });
    let params = {
      'data': {
        'businessName': this.selectedBusinessName,
        'cidn': this.currentCidn,
        'isClosedTicket': this.isClosedTicket
      }
    }
    this.createGrid(params);
  }

  navigateToCreateTickets() {
    this.router.navigate(['/support']);
  }

  closeTickets($event) {
    this.disableBussiness = true;
    this.enableSearch();
    this.filterSearchInput($event);
  }
  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.wrkListSvc.getGridHeader().then(() => {
        this.columnDefs = this.wrkListSvc.gridHeaderDataObj.headers;
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData(params) {
    this.isSearched = true;
    const param = params || null;
    return new Promise((resolve, reject) => {
      this.wrkListSvc.getGridData(params).then(() => {
        this.rowData = this.wrkListSvc.worklistGridData;
        for (let i = 0; i < this.rowData.length; i++) {
          const selectedRow = this.rowData[i];
          for (const key in selectedRow) {
            if (!selectedRow[key] || selectedRow[key].toLowerCase() === 'null') {
              selectedRow[key] = '';
            }
          }
        }
        this.rowData.forEach(item => {
          item.createdDate = item.createdDate ? this.currentMoment(item.createdDate).format('DD MMM YYYY') : '';
          item.updatedDate = item.updatedDate ? this.currentMoment(item.updatedDate).format('DD MMM YYYY') : '';
        });
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }
  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    // this.gridConfig.enableFilter = true;
    // this.gridConfig.enableSorting = true;
    // Disable row selection on click.
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
    this.enableSearch();
  }
  getLabels() {
    return new Promise((resolve, reject) => {
      this.wrkListSvc.getWorkListModel().then(() => {
        this.aemLabelAuthoring = this.wrkListSvc.worklistLabelAuthoring;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.searchModel.labelAuthoring = this.aemLabelAuthoring.form.searchBox;
        //this.bandwidthTooltipContentModel.content = this.aemLabelAuthoring.miscellaneous.tooltipContent;
        //this.downloadDetailsTooltipContentModel.content = this.aemLabelAuthoring.miscellaneous.downloadDetailsToolTipContent;
        this.downloadSummaryTooltipContentModel.content = this.aemLabelAuthoring.miscellaneous.downloadSummaryToolTipContent;
        resolve(true);
        if (this.commonService.isAmplitelAdmin) {
          this.getBusinessNames();
        } else {
          this.refreshTickets();
        }
      }).catch((err) => {
        reject(false);
      });
    });
  }

  filterSearchInput($event?) {
    let id, loggedDate, toLoggedDate;
    if ($event) {
      this.searchDetails = $event;
      id = this.searchDetails.id ? this.searchDetails.id : null;
      loggedDate = this.searchDetails.serchfromDate ? this.searchDetails.serchfromDate : null;
      toLoggedDate = this.searchDetails.serchtoDate ? this.searchDetails.serchtoDate : null;
    }
    const payload = {
      'data': {
        'serviceProviderName': this.env['serviceProviderName']
      }
    };
    if (id) {
      (<any>payload['data']).id = id;
      (<any>payload['data']).cidn = this.currentCidn;

    }
    if (loggedDate && toLoggedDate) {
      (<any>payload['data']).fromLoggedDate = loggedDate;
      (<any>payload['data']).toLoggedDate = toLoggedDate;
    }

    //(<any>payload['data']).businessName = this.selectedBusinessName;
    (<any>payload['data']).cidn = this.currentCidn;

    if ((<HTMLInputElement>document.getElementById('closed')).checked) {
      (<any>payload['data']).isClosedTicket = true;
    }
    else {
      (<any>payload['data']).isClosedTicket = false;
    }

    this.resetGrid(payload);
  }

  resetGrid(payload) {
    this.searchModel.isSearchOn = this.searchOn = !this.searchOn;
    this.gridInitialized = false;
    if (!payload) {
      payload = {
        'data': {
          //'businessName': this.selectedBusinessName,
          'cidn': this.currentCidn,
          'isClosedTicket': this.isClosedTicket
        }
      };
      (<HTMLInputElement>document.getElementById('closed')).checked = false;
    }
    this.createGrid(payload);
  }

  downloadSummary() {
    const data = [];
    const dataHeaders = ['Enquiry ID', 'Site Reference', 'Site Address', 'Enquiry Type', 'Enquiry Sub-Type', 'Short Description', 'Status', 'Logged By', 'Logged Date', 'Last Updated'];
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    } else {
      if (this.rowData && this.rowData.length) {
        dataToBeDownloaded = this.rowData;
      }
    }
    dataToBeDownloaded.forEach((selectedRow) => {
      if (selectedRow && selectedRow.status && selectedRow.status === 'Closed') {
        const estimatedResolutionAbbr = selectedRow.restoredDate ? this.currentMoment(selectedRow.restoredDate).tz(this.selectedTimeZone).format('z') : '';
        selectedRow.estimatedResolutionDate = selectedRow.restoredDate ? this.currentMoment(selectedRow.restoredDate).format('DD MMM YYYY HH:mm ') + estimatedResolutionAbbr : '-';

      } else {
        if (selectedRow && selectedRow.estimatedResolutionDate && selectedRow.estimatedResolutionDate === 'null') {
          selectedRow.estimatedResolutionDate = '-';
        }
      }
      data.push({
        'Enquiry ID': selectedRow.ticketNumber ? selectedRow.ticketNumber : '',
        'siteReference': selectedRow.siteReference ? selectedRow.siteReference : '',
        'siteAddress': selectedRow.siteAddress ? selectedRow.siteAddress : '',
        'Enquiry Type': selectedRow.ticketCategory ? selectedRow.ticketCategory : '',
        'Enquiry Sub-Type': selectedRow.ticketSubCategory ? selectedRow.ticketSubCategory : '',
        'Short Description': selectedRow.ticketShortDesc ? selectedRow.ticketShortDesc : '',
        'Status': selectedRow.state ? selectedRow.state : '',
        'Logged By': selectedRow.contactName ? selectedRow.contactName : '',
        'Logged Date': selectedRow.createdDate ? selectedRow.createdDate : '',
        'Last Updated': selectedRow.updatedDate ? selectedRow.updatedDate : '',
      });
    });
    const now = this.currentMoment().format('YYYYMMDDHHmmSS');
    let fileName = `Amplitel_My-Enquiries`;
    return new Angular5Csv(data, fileName, { headers: dataHeaders });
  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  onSelectionChanged(arg) {
    const selectedRows = arg.gridApi.getSelectedRows();
    let selectedRowsString = '';
    let sysid = '';
    let ticketType = '';
    selectedRows.forEach(function (selectedRow, index) {
      if (index !== 0) {
        selectedRowsString += ', ';
        sysid += ',';
        ticketType += ',';
      }
      selectedRowsString += selectedRow.ticketNumber;
      sysid += selectedRow.sysTicketId;
      // businessName += selectedRow.serviceProviderName ? selectedRow.serviceProviderName : '' ;
      ticketType += selectedRow.type;
    });
    let ticketId, subStatus, wfInstanceId;
    if (selectedRows && selectedRows.length) {
      ticketId = selectedRows[0].id ? selectedRows[0].id : '';
      // businessName = selectedRows[0].serviceProviderName ? selectedRows[0].serviceProviderName : '';
      // ticketType = selectedRows[0].type ? selectedRows[0].ticketType.toLowerCase() ? selectedRows[0].ticketType.toLowerCase() : '' : '';
      status = selectedRows[0].status ? selectedRows[0].status.toLowerCase() ? selectedRows[0].status.toLowerCase() : '' : '';
      subStatus = selectedRows[0].subStatus ? selectedRows[0].subStatus.toLowerCase() ? selectedRows[0].subStatus.toLowerCase() : '' : '';
      wfInstanceId = selectedRows[0].wfInstanceId ? selectedRows[0].wfInstanceId : ''
    }
    let systicketid = sysid;
    this.ticketSummaryService.getServiceDetails(systicketid).then(res => {
      //this.loader.setLoader(false);              
      this.router.navigate([`ticket-summary/${this.currentCidn}/${selectedRowsString}`]);
    }).catch(() => {
      this.showToasterMessage('Something went wrong.Please try again later', '', this.options, 'error');
      //this.loader.setLoader(false);              
    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.options.enableHtml = true;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  gridReady(params) {
    //this.gridApi = params.api;
    //this.gridColumnApi = params.columnApi;
    setTimeout(() => {
      if (this.rowData && this.rowData.length && this.dashboardCardFilter && this.dashboardCardFilter.status) {
        var filterComponent = this.gridModel.gridOptions.api.getFilterInstance('grid-status');
        if (filterComponent) {
          filterComponent.setModel({ value: this.dashboardCardFilter.status });
        }
        this.gridModel.gridOptions.api.onFilterChanged();
      }
    }, 800)
  }


  inputChange($event) {
    this.enableSearch();
  }
  clearsearch() {
    (<HTMLInputElement>document.getElementById("text")).value = '';
    this.gridModel.gridOptions.api.setQuickFilter('');

  }
  quickSearch() {
    this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
  }
  enableSearch() {
    const searchId = document.getElementById('searchIdInput');
    const fromDate = document.getElementById('fromDateDatePicker');
    const toDate = document.getElementById('fromDateDatePicker');
    const searchButtons = document.getElementById('searchButton');
    if ((<HTMLInputElement>searchButtons)) {
      if ((<HTMLInputElement>searchButtons).disabled && ((<HTMLInputElement>document.getElementById('closed')).checked ||
        (<HTMLInputElement>fromDate).value || (<HTMLInputElement>toDate).value)) {
        (<HTMLInputElement>searchButtons).disabled = false;
        this.searchModel.isSearchOn = true;
        this.searchModel.isSearchDisabled = false;
      } else if (!(<HTMLInputElement>searchId).value && !(<HTMLInputElement>fromDate).value && !(<HTMLInputElement>toDate).value &&
        !(<HTMLInputElement>document.getElementById('closed')).checked) {
        (<HTMLInputElement>searchButtons).disabled = true;
        this.searchModel.isSearchOn = false;
        this.searchModel.isSearchDisabled = true;
      }
    }
  }

  createRow() {
    var rowData = [];
    for (var i = 0; i < 100; i++) {
      // create sample row item
      var rowItem = {
        // is is simple
        a: 'aa' + Math.floor(Math.random() * 10000),
        // but b, c, d and e are all complex objects
        b: {
          name: 'bb' + Math.floor(Math.random() * 10000)
        },
        c: {
          name: 'cc' + Math.floor(Math.random() * 10000)
        },
        d: {
          name: 'dd' + Math.floor(Math.random() * 10000)
        },
        e: {
          name: 'ee' + Math.floor(Math.random() * 10000)
        }
      };
      rowData.push(rowItem);
    }
    return rowData;
  }

  focusToRecords() {
    setTimeout(() => {
      const focusableElement = document.getElementById('noOfRecords');
      focusableElement.focus();
    }, 10);
  }
  focusOnTop(event) {
    if (event && this.hideDropdown) {
      setTimeout(() => {
        const focusableElement = document.getElementById('text');
        focusableElement.focus();
      }, 10);
    } else if (event && !this.hideDropdown) {
      setTimeout(() => {
          const focusableElement = <HTMLElement>document.getElementsByClassName('ngx-select')[0];
          focusableElement.focus();
      }, 10);
    }
  }
}