<div class="page-bg">
<div class="container-fluid page-container create-customer" *ngIf="modifyCustomerLabels">
  <h1 class="page-header" id="modifyCustomerhead">{{modifyCustomerLabels.pageHeader.pageHeaderLabel}} {{customerData.businessName}}</h1>
  <div class="wrapper">
    <h2 class="create-customer-header" automationType="label" automationId="lblChoosePlan">
      {{modifyCustomerLabels.pageHeader.pageSubHeaderLabel}}
    </h2>
    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
    <label class="enquiry-form-mandatory-lbl">Fields marked with <span class="aesterisk">*</span> are mandatory</label>
    <form [formGroup]="modifyCustomerForm" id="modifyCustomer" #modifyCustomerTicket="ngForm">
      <div class="form-group">
        <div class="row">
          <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 userNameWidth">
            <app-cui-textfield [(model)]="modifyBusinessNameModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4 cidn-div">
            <app-cui-textfield [(model)]="modifyCidnModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
          <div class="dropdown-contact-options col-xs-12 col-sm-4 col-lg-3 col-md-3">
            <label class="statusLbl checkLbl asteriskdropdown" automationType="label" automationId="lblrole" id="modifyStatusHeader"
              attr.aria-label={{modifyCustomerLabels.form.statusModel}} role="tabpanel">
              {{modifyCustomerLabels.form.statusModel}}
            </label>
            <app-cui-searchable-dropdown name="modifyStatus" [formControlName]="'modifyStatus'" [model]="statusModel"
              [labelName]="modifyCustomerLabels.form.statusModel"
              [placeholder]="modifyCustomerLabels.form.statusPlaceholder" [isRequired]=true [items]=statusItems
              automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false id="statusDropdown"
              class="user-dropDown"
              [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.modifyStatus.invalid && modifyCustomerForm.controls.modifyStatus.touched )}"
              required>
            </app-cui-searchable-dropdown>
            <img src="../../../assets/images/select-arrow.png" 
            class="dropdown-arrow" alt=""/>
            <div class="row ml-0" aria-live="assertive">
              <span role="alert"
                *ngIf="modifyCustomerForm.controls.modifyStatus.invalid && modifyCustomerForm.controls.modifyStatus.touched"
                class="help-block errorMsg">
                {{modifyCustomerLabels.form.statusRequiredMessage}}
              </span>
            </div>
            <span class="asterisk_input"> </span>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-xs-12 col-sm-2 col-lg-2 col-md-2 cidn-div spid">
            <app-cui-textfield [(model)]="modifySpidModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
        </div> -->
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
            <app-cui-textfield [(model)]="modifyAbnModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
          <div class="col-xs-12 col-sm-4 col-lg-3 col-md-4">
            <app-cui-textfield [(model)]="modifyMsoIdModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
        </div>

        <div class="row details-table" *ngIf="customerData">
          <table class="table">
            <caption class="sr-only">Customer Creation/Modification details</caption>
            <tr>
              <th>{{modifyCustomerLabels.form.created}}</th>
              <th>{{modifyCustomerLabels.form.createdBy}}</th>
              <th>{{modifyCustomerLabels.form.lastModifiedDate}}</th>
              <th>{{modifyCustomerLabels.form.lastModifiedBy}}</th>
            </tr>
            <tr>
              <td><span style="padding:0;"
                  *ngIf="customerData && customerData.createdDate && customerData.createdDate !=='null'">{{customerData.createdDate}}</span>
              </td>
              <td><span style="padding:0;"
                  *ngIf="customerData && customerData.createdBy && customerData.createdBy !== 'null'">{{customerData.createdBy}}</span>
              </td>
              <td><span style="padding:0;"
                  *ngIf="customerData && customerData.modifiedDate && customerData.modifiedDate !== 'null'">{{customerData.modifiedDate}}</span>
              </td>
              <td><span style="overflow-wrap:anywhere;padding:0;"
                  *ngIf="customerData && customerData.modifiedBy && customerData.modifiedBy !== 'null'">{{customerData.modifiedBy}}</span>
              </td>
            </tr>
          </table>
        </div>

        <div class="row">
          <!-- class="primary-btn xl-btn create-mobile-view-submit" -->
          <div class="button-container customer-button-container">
            <button type="button" [disabled]="!modifyCustomerForm.valid"
               class="red-primary-btn enquiry-submit-btn"
              (click)="onSubmit()">{{modifyCustomerLabels.form.submit}}</button>
              <!-- class="secondary-btn xl-btn create-mobile-view-cancel" -->
            <br>
              <button type="button" class="red-secondary-btn"
              (click)="cancelCreate()">{{modifyCustomerLabels.form.cancel}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>