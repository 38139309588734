import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ProfileService } from '../services/profile.service';

import { LoadingServiceService } from '../services/loading-service.service';

@Component({
  selector: 'app-set-default-business',
  templateUrl: './set-default-business.component.html',
  styleUrls: ['./set-default-business.component.less']
})

export class SetDefaultBusinessComponent implements OnInit {

  public primaryBusinessGroup: FormGroup;
  
  public defaultBusinessName;
  public profileBusinessNamesList: any = [];
  public allBusinessRoleData: any;
  public primaryBusinessName;
  public selectedCIDN;
  profile: any = {};
  isOpenPopUp = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loader: LoadingServiceService,
    private commonService: CommonService,
    private profileService: ProfileService
  ) {
    this.primaryBusinessGroup = this.fb.group({
      defaultBusinessNames: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.loader.setLoader(true);
    this.isOpenPopUp = false;
    this.profile = this.profileService.getProfileDetails();
    this.getAllBusinessNames();
  }

  closeModal() {
    this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedCIDN[0] });
    this.router.navigateByUrl('/site-locator');
  }

  onBusinessNameChanged(newValue) {
    if (newValue) {
      this.primaryBusinessName = newValue;
      this.selectedCIDN = this.allBusinessRoleData.filter(key => {
        return key['Business name'].toLowerCase() === this.primaryBusinessName.toLowerCase();
      }).map(key => {
        return key['CIDN'];
      });
    }
  }

  getAllBusinessNames() {
    this.commonService.getAllBusinessNames().then(res => {
      const allBusinessRoleData: any = res;
      this.allBusinessRoleData = res;
      if (allBusinessRoleData.length > 0) {
        (allBusinessRoleData || []).forEach(val => {
          if (this.profileBusinessNamesList.indexOf(val['Business name']) === -1) {
            this.profileBusinessNamesList.push(val['Business name']);
          }
        });
        this.profileBusinessNamesList.sort(this.itemSort);
      }
      if (this.profileBusinessNamesList.length == 1) {
        this.primaryBusinessName = this.profileBusinessNamesList[0];
        this.selectedCIDN = this.allBusinessRoleData.filter(key => {
          return key['Business name'].toLowerCase() === this.primaryBusinessName.toLowerCase();
        }).map(key => {
          return key['CIDN'];
        });
        this.commonService.updateValue({ businessName: this.primaryBusinessName, cidn: this.selectedCIDN[0] });
        this.router.navigateByUrl('/site-access');
      }
      this.isOpenPopUp = true;
      this.loader.setLoader(false);
    }).catch((err) => {
      // this.router.navigateByUrl('/error');
    });
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    }
    else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

}
