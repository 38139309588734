import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
// import { CreateCustomerData } from '../customer.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomerService } from '../customer.service';
import { UtilityService } from '../../services/utility.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-modify-customer',
  templateUrl: './modify-customer.component.html',
  styleUrls: ['./modify-customer.component.less']
})
export class ModifyCustomerComponent implements OnInit, OnDestroy {
  customerData: any;
  options: GlobalConfig;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public modifyCustomerLabels: any;
  // CreateCustomerData;
  public statusItems = [];
  customerRegex = /^[^,`=~#!<>+@]+$/;
  // cidnRegex = /^[0-9]+$/;
  cidnRegex = /^[0-9]{10}$/g
  abnRegex = /^\d{11}$/;
  msoIdRegex = /^[^,`=~#!<>+@]+$/;
  public modifyBusinessNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyBusinessName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxbusinessName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyBusinessName',
    tabindex: '',
    maxlength: '50',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifyBusiness',
    formControlName: 'modifyBusiness',
    isDisabled: false,
    showValidationIcon: true
  };
  public modifyCidnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessCidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessCidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyCidn',
    tabindex: '',
    maxlength: '10',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifyCidn',
    formControlName: 'modifyCidn',
    isDisabled: false,
    showValidationIcon: true
  };

  public modifySpidModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessCidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessCidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifySpid',
    tabindex: '',
    maxlength: '5',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'modifySpid',
    formControlName: 'modifySpid',
    isDisabled: false,
    showValidationIcon: true
  };
  public modifyAbnModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessAbn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessAbn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyAbn',
    tabindex: '',
    maxlength: '11',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'modifyAbn',
    formControlName: 'modifyAbn',
    isDisabled: false,
    showValidationIcon: false
  };
  public modifyMsoIdModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMymodifyBusinessMsoid',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxmodifyBusinessMsoid',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divuserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'modifyMsoid',
    tabindex: '',
    maxlength: '15',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'modifyMsoid',
    formControlName: 'modifyMsoid',
    isDisabled: false,
    showValidationIcon: false
  };
  public statusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public modifyCustomerForm: FormGroup;

  // tslint:disable-next-line: max-line-length
  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, 
    private customerService: CustomerService, private toastr: ToastrService, 
    private utility: UtilityService,private titleService: Title,
    private sharedService: SharedService) { 
    this.titleService.setTitle('Amplitel Modify a Customer');
    }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
    this.options = this.toastr.toastrConfig;
    this.getLabels();
    const CUSTOMER_DATA = this.customerService.getCreateCustomerData();
    this.customerData = CUSTOMER_DATA;
    if (this.customerData) {
      this.modifyBusinessNameModel.inputText = this.customerData ? this.customerData.businessName : '';
      this.modifyCidnModel.inputText = this.customerData ? this.customerData.cidn : '';
      this.modifySpidModel.inputText = this.customerData.spid && this.customerData.spid.toLowerCase() !== 'empty' ? this.customerData.spid : '';
      this.modifyAbnModel.inputText = this.customerData ? this.customerData.abn : '';
      this.modifyMsoIdModel.inputText = this.customerData ? this.customerData.mso_id : '';
      // tslint:disable-next-line: max-line-length tslint:disable-next-line: radix
      const NO_OF_USERS = this.customerData.no_of_users ? parseInt(this.customerData.no_of_users) ? parseInt(this.customerData.no_of_users) : 0 : 0;
      this.modifyCidnModel.isDisabled = true;
      
      if (NO_OF_USERS) {
        this.modifyBusinessNameModel.isDisabled = true;
        // this.modifyCidnModel.isDisabled = true;
        if (this.customerData.spid && (this.customerData.spid !== '' && this.customerData.spid !== null && this.customerData.spid.toLowerCase() !== 'empty')) {
          this.modifySpidModel.isDisabled = true;
        }
      }
      this.modifyCustomerForm = this.fb.group({
        modifyBusiness: [this.modifyBusinessNameModel.inputText, [Validators.required, Validators.pattern(this.customerRegex)]],
        modifyCidn: ["", [Validators.required, Validators.pattern(this.cidnRegex)]],
        modifySpid: ['00', [Validators.required, Validators.pattern(this.utility.getShortNumberRegEx())]],
        modifyStatus: [this.customerData.status, [Validators.required]],
        modifyAbn: ['', [Validators.pattern(this.abnRegex)]],
        modifyMsoid: ['', [Validators.pattern(this.msoIdRegex)]],
      });
    } else {
      this.router.navigate(['manageCustomers/createCustomer']);
    }
  }

  getLabels() {
    this.customerService.getModifyCustomerModel().then((data: any) => {
      this.modifyCustomerLabels = data;
      this.statusItems = this.modifyCustomerLabels.form.statusItems;
      this.modifyBusinessNameModel.headerText = this.modifyCustomerLabels.form.businessName;
      this.modifyBusinessNameModel.placeholderCaption = this.modifyCustomerLabels.form.businessNamePlaceholder;
      this.modifyBusinessNameModel.validationMessage = this.modifyCustomerLabels.form.businessNameValidationMessage;
      this.modifyBusinessNameModel.requiredText = this.modifyCustomerLabels.form.businessNameRequiredMessage;
      this.modifyCidnModel.headerText = this.modifyCustomerLabels.form.cidn;
      this.modifyCidnModel.placeholderCaption = this.modifyCustomerLabels.form.cidnPlaceholder;
      this.modifyCidnModel.validationMessage = this.modifyCustomerLabels.form.cidnValidationMessage;
      this.modifyCidnModel.requiredText = this.modifyCustomerLabels.form.cidnRequiredMessage;
      this.modifySpidModel.headerText = this.modifyCustomerLabels.form.spid;
      this.modifySpidModel.placeholderCaption = this.modifyCustomerLabels.form.spidPlaceholder;
      this.modifySpidModel.validationMessage = this.modifyCustomerLabels.form.spidValidationMessage;
      this.modifySpidModel.requiredText = this.modifyCustomerLabels.form.spidRequiredMessage;
      this.modifyAbnModel.headerText = this.modifyCustomerLabels.form.abn;
      this.modifyAbnModel.placeholderCaption = this.modifyCustomerLabels.form.abnPlaceholder;
      this.modifyAbnModel.validationMessage = this.modifyCustomerLabels.form.anbValidationMessage;
      this.modifyMsoIdModel.headerText = this.modifyCustomerLabels.form.msoid;
      this.modifyMsoIdModel.placeholderCaption = this.modifyCustomerLabels.form.msoidPlaceholder;
      this.modifyMsoIdModel.validationMessage = this.modifyCustomerLabels.form.msoidValidationMessage;

    }).catch((error) => {
    });
  }

  onSubmit(): any {
    let errMsgs:string = 'Unable to create/update the customer account in Enquiry System, please contact Level-1 support team';
    const DATA = this.modifyCustomerForm.value;
    this.customerService.modifyCustomer(DATA, this.customerData.cidn).then((response) => {
      this.customerService.createCustomerFormData = this.modifyCustomerForm.value;
      this.customerService.createCustomerFormData['page'] = 'Modify';
      this.router.navigate(['manageCustomers/customers']);
    }).catch((err) => {
      if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
        this.showToasterMessage(this.modifyCustomerLabels.form.errMsg, '', this.options, 'error');
      } else {
        const msg = err.error.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      }
    });
  }

  cancelCreate() {
    this.router.navigate(['manageCustomers/customers']);
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.autoDismiss=true;
    this.options.timeOut=5000;
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }

  ngOnDestroy() {
    // this.toastr.clear();
  }

}
