import { Component, OnInit, DoCheck, HostListener, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileData } from './profile.model';
import { CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ProfileService } from '../services/profile.service';
import { UtilityService } from '../services/utility.service';
import { UsersService } from '../users/users.service';
import { SharedService } from '../services/shared.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, DoCheck {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  tabName: string;
  checkBox: any;
  altContactCheckBoxInstance: any;
  checkBoxValue: any = false;
  isChecked: any;
  updatedSmsValue: boolean;
  updatedEmailValue: boolean;
  resolvedSmsValue: boolean;
  checkedSmsValue: boolean;
  resolvedEmailValue: boolean;
  checkedEmailValue: boolean;
  notificationData: any;
  notifyResolvedEmail: boolean;
  notifyCheckedEmail: boolean;
  notifyResolvedSms: boolean;
  notifyCheckedSms: boolean;
  notifyUpdatesEmail: boolean;
  notifyUpdatesSms: boolean;
  businessNames: any = [];
  profileBusinessNames: any = [];
  selectedCIDN: any = [];
  public emailAddress: any;
  adminButton = false;
  cidns: any[];
  params: { cidn: any; customerLevel: string; loggedInEmail: string };
  public closeModalClicked: boolean = true;
  flag: boolean;
  public loggedInEmail: string;
  public phoneNumber;
  public adminlabel;
  selectedTabIndex: string;

  constructor(public router: Router,
    private commonService: CommonService,
    private eRef: ElementRef,
    private profileService: ProfileService, private fb: FormBuilder, private usersService: UsersService,
    private utility: UtilityService, private toastr: ToastrService,
    private route: ActivatedRoute,
    private sharedService: SharedService) {

     }

  public myProfileForm: FormGroup;
  public aemLabelAuthoring: ProfileData;
  targetUrl: String = '';
  userData: any = {};
  profile: any = {};
  editNumber = true;
  marked = false;
  showMenu: Boolean = false;
  isModalOpen: Boolean = false;
  theCheckbox = false;
  tableCol = ['Business Name', 'Role'];
  rowData = [];
  sampleData = [
    {
      "role": "ampliteladmin",
      "business": "AMPLITEL"
    },
    {
      "role": "amplitelbilladmin",
      "business": "AMPLITEL"
    }
  ]
  profileBusinessName;
  // contactNumber = '';
  contactNumber = '0918765444';

  allBusinessRoleData;
  spinner: Boolean = false;
  options: GlobalConfig;
  type = ['success', 'error', 'info', 'warning'];

  public phoneModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'phoneNumber',
    tabindex: '',
    maxlength: '18',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'phoneNumber',
    formControlName: 'phoneNumber',
    isDisabled: this.editNumber,
    showValidationIcon: false
  };
  allBusinessNames: any = [];
  public selectBusinessNameLabel;
  defaultCIDN: any;
  primaryBusinessName;
  public defaultBusinessName = undefined;

  public notifyUpdatesModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'notifyUpdatesFormControl',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'notifyUpdatesFormControl',
    formControlName: 'notifyUpdatesFormControl',
    isDisabled: false,
    showValidationIcon: false
  };
  public notifyResolvedModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'notifyResolvedFormControl',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'notifyResolvedFormControl',
    formControlName: 'notifyResolvedFormControl',
    isDisabled: false,
    showValidationIcon: false
  };

  public notifyCheckedModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblPhoneNumber',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxPhoneNumber',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divPhoneNumberValidationErrMsg',
    placeholderCaption: '',
    id: 'notifycheckedFormControl',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'notifycheckedFormControl',
    formControlName: 'notifycheckedFormControl',
    isDisabled: false,
    showValidationIcon: false
  };
  sms_hide: boolean;
  public isHomeScreen = true;
  public roles;
  serviceOffering = false;
  buttonDisable = false;

  isTermsPage = false;
  selectedBusinessName = '';

  ngOnInit() {

    this.router.events.subscribe((router) => {
      if (router instanceof NavigationEnd) {
        this.isTermsPage = false;
        if (this.router.url === '/terms-and-conditions') {
          this.isTermsPage = true;
        } else if (this.router.url === '/AMS' || this.router.url === '/acknowledge') {
          if (!sessionStorage.getItem('isLoginFlag')) {
            this.isTermsPage = true;
          }
        }
      }
    });

    const primaryBusiness = this.commonService.getPrimaryValue();
    this.selectedBusinessName = primaryBusiness.businessName || '';
    this.tabName = 'roles';
    const userRoleDetails = this.commonService.getroleDetails();
    this.roles = userRoleDetails.roleList;
    this.notifyUpdatesModel.pattern = String(this.utility.getEmailRegEx());
    this.notifyResolvedModel.pattern = String(this.utility.getEmailRegEx());
    if (userRoleDetails && userRoleDetails.cidnList && userRoleDetails.cidnList.length) {
      this.cidns = userRoleDetails.cidnList;
    }
    if (userRoleDetails.roleList.length === 0 ||
      userRoleDetails.appList.length !== userRoleDetails.roleList.length ||
      userRoleDetails.roleList.length !== userRoleDetails.cidnList.length) {
    } else {
      this.options = this.toastr.toastrConfig;
      this.profile = this.profileService.getProfileDetails();
      this.getLabels();
      this.emailAddress = this.profile.contactEmail ? this.profile.contactEmail : '';
      this.targetUrl = this.profile.targetUrl;

      if (this.emailAddress) {
        this.notifyResolvedModel.inputText = this.emailAddress;
        this.notifyUpdatesModel.inputText = this.emailAddress;
      }
      this.myProfileForm = this.fb.group({
        phoneNumber: ['', [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]]
      });
    }
    this.setHamburgerMenuItems();
  }

  ngDoCheck() {
    this.isHomeScreen = this.router.url === '/' ||
      this.router.url === '/enquiry' ||
      this.router.url === '/logout' ? true : false;
  }

  selectModal(id: string) {
    // this.router.navigate(['/services'], { relativeTo: this.route, queryParams: {
    //   tab: id
    // }});
    if (id) {
      this.selectedTabIndex = id;
      this.commonService.updateModal(this.selectedTabIndex);
    }
  }
  navigateToHome() {
    if (sessionStorage.getItem('isLoginFlag')){
      this.router.navigate(['/']);
    }   
  }
  keydownTab(event) {
    this.sharedService.focusOnElement('header_logo');
  }
  focusToMainContent() {
    if (this.router.url === '/') {
      if ((this.roles.includes('enquiryonlyuser') &&
        !this.roles.includes('enquiryplusamsuser') &&
        !this.roles.includes('customeradmin') &&
        !this.roles.includes('ampliteladmin') &&
        !this.roles.includes('billviewuser') &&
        !this.roles.includes('amplitelbilladmin'))) {
        this.sharedService.focusOnElement('submit-enquiry-btn');
      } else {
        this.sharedService.focusOnElement('tower-ams');
      }
    } else if (this.router.url === '/enquiry') {
      this.manageHeaderFocus('enquiryTopic');
    } else if (this.router.url === '/logout') {
      this.sharedService.focusOnElement('logout-btn');
    }
    else if (this.router.url === '/manageCustomers/createCustomer') {
      this.manageHeaderFocus('customerCreate');
    }
    else if (this.router.url === '/manageUsers/createUser') {
      this.manageHeaderFocus('createUserhead');
    }
    else if (this.router.url === '/manageCustomers/customers') {
      this.manageHeaderFocus('customerList');
    }
    else if (this.router.url === '/manageUsers/myUser') {
      this.manageHeaderFocus('myUsers');
    }
    else if (this.router.url === '/manageUsers/modifyUsers') {
      this.manageHeaderFocus('modifyUserhead');
    }
    else if (this.router.url === '/companynotifications') {
      this.manageHeaderFocus('CompanyNotification');
    }
    else if (this.router.url === '/company-notifications-edit-preference') {
      this.manageHeaderFocus('addPreference');
    }
    else if (this.router.url === '/billing') {
      this.manageHeaderFocus('Billing');
    }
    else if (this.router.url === '/manageCustomers/modifyCustomers') {
      this.manageHeaderFocus('modifyCustomerhead');
    }
    else if (this.router.url === '/company-notifications-add-preference') {
      this.manageHeaderFocus('addPreferencehead');
    }
    else if (this.router.url === '/acknowledge') {
      this.manageHeaderFocus('ackPopup');
    }
    else if (this.router.url === '/tickets/mytickets') {
      this.manageHeaderFocus('mytickets');
    }
    else if (this.router.url === '/enquiries/myenquiries') {
      this.manageHeaderFocus('customerList');
    }
    else if (this.router.url === '/site-access') {
      this.manageHeaderFocus('siteAccess');
    }
    else if (this.router.url.includes('/ticket-summary')) {
      this.manageHeaderFocus('ticketSum');
    } else if (this.router.url === '/site-locator') {
      this.manageHeaderFocus('siteLocator');
    }
  }

  manageHeaderFocus(id: string) {
    let focusableElement;
    focusableElement = document.getElementById(id);
    focusableElement.setAttribute('tabindex', '0');
    focusableElement.addEventListener('blur', () => {
      focusableElement.removeAttribute('tabindex');
    });
    setTimeout(() => {
      if (focusableElement) {
        focusableElement.focus();
      }
    }, 10);
  }

  openAdminDropdown() {
    (<HTMLElement>document.getElementsByClassName('admin-dropdown')[0]).style.display = 'block';
  }
  openServiceDropdown() {
    (<HTMLElement>document.getElementsByClassName('dropdown-content')[0]).style.display = 'block';

  }
  closeService() {
    (<HTMLElement>document.getElementsByClassName('dropdown-content')[0]).style.display = 'none';
  }
  closeAdmin() {
    (<HTMLElement>document.getElementsByClassName('admin-dropdown')[0]).style.display = 'none';
  }


  getLabels() {
    return new Promise((resolve, reject) => {
      this.profileService.getProfileModel().then(() => {
        this.aemLabelAuthoring = this.profileService.profileAuthoringDataObj;
        this.notifyUpdatesModel.headerText = this.aemLabelAuthoring.form.notificationPrefEmail.headerText
        this.notifyUpdatesModel.placeholderCaption = this.aemLabelAuthoring.form.notificationPrefEmail.placeholderCaption;
        this.notifyUpdatesModel.validationMessage = this.aemLabelAuthoring.form.notificationPrefEmail.validationMessage;
        this.notifyUpdatesModel.requiredText = this.aemLabelAuthoring.form.notificationPrefEmail.requiredText;
        this.notifyResolvedModel.headerText = this.aemLabelAuthoring.form.notificationPrefEmail.headerText
        this.notifyResolvedModel.placeholderCaption = this.aemLabelAuthoring.form.notificationPrefEmail.placeholderCaption;
        this.notifyResolvedModel.validationMessage = this.aemLabelAuthoring.form.notificationPrefEmail.validationMessage;
        this.notifyResolvedModel.requiredText = this.aemLabelAuthoring.form.notificationPrefEmail.requiredText;
        // this.phoneModel.headerText = this.profile.contactEmail;
        this.phoneModel.headerText = this.aemLabelAuthoring.form.headerText;
        this.phoneModel.placeholderCaption = this.aemLabelAuthoring.form.placeholderCaption;
        this.phoneModel.validationMessage = this.aemLabelAuthoring.form.validationMessage;
        this.phoneModel.requiredText = this.aemLabelAuthoring.form.requiredText;
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }

  filterRolesForCIDN(newValue) {
    let associatedRoles = [];
    let profileCidn = this.allBusinessRoleData.BusinessNameDetails.filter(key => {
      return key['Business name'].toLowerCase() === newValue.toLowerCase();
    }).map(key => {
      return key['CIDN'];
    });
    return { "currentSelectedCIDN": profileCidn[0] }
  }

  getCidnFromBusinessName(businessName: string) {
    if (businessName && businessName.toLowerCase()) {
      if (this.allBusinessRoleData && this.allBusinessRoleData.BusinessNameDetails.length) {
        return this.allBusinessRoleData.BusinessNameDetails.filter(key => key['Business name'].toLowerCase() === businessName.toLowerCase()).map(key => key['CIDN'])
      }
    } else {
      return [];
    }
  }

  toggleEdit() {
    this.editNumber = !this.editNumber;
    this.phoneModel.isDisabled = this.editNumber;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showMenu = false;
    }
  }

  onSubmit() {
    const DATA = { 'userName': this.profile.contactEmail, 'id': this.userData.id };
    const UPDATE = [{
      'opValue': 'replace',
      'name': { 'givenName': this.userData.name.givenName, 'familyName': this.userData.name.familyName },
      'phone': this.myProfileForm.value.phoneNumber
    }];
    this.usersService.modifyPhoneNumber(UPDATE, DATA).then(result => {
      document.getElementById('wrapper').style.overflowY = 'auto';
      this.editNumber = true;
      this.phoneModel.isDisabled = this.editNumber;
      this.showToasterMessage(this.aemLabelAuthoring.labels.successMsg, '', this.options, 'success');
      this.contactNumber = result['data']['wholesale-users'].phoneNumbers[0].value;

      this.rowData = [];
      this.userData.roles.forEach(val => {
        this.rowData.push({ 'business': val.businessName, 'app': val.appName.toUpperCase(), 'role': val.value });
      });
    }).catch((err) => {
      this.showToasterMessage('Failed to update phone number!', '', this.options, 'error');
      document.getElementById('wrapper').style.overflowY = 'auto';
      this.editNumber = true;
      this.phoneModel.isDisabled = this.editNumber;
    });
  }

  async getUserProfileData() {
    const data = { 'userName': this.profile.contactEmail, src_screen: 'profile' };
    //const data = { 'userName': 'ayush.sahu@team.telstra.com' };
    await this.usersService.getUserData(data, 'profile').then(res => {
      if (res && res.status === 200) {
        this.userData = res['data']['wholesale-users'][0];
        this.commonService.setUserBusinessNames(this.userData.roles);
        this.contactNumber = this.userData.phoneNumbers[0].value;
        if (this.userData && this.userData.roles && this.userData.roles.length) {
          this.rowData = [];
          this.userData.roles.forEach(val => {
            this.rowData.push({ 'business': val.businessName, 'app': val.appName.toUpperCase(), 'role': val.value });
          });
        }
        if (this.contactNumber) {
          this.myProfileForm.controls['phoneNumber'].setValue(this.contactNumber);
          this.phoneModel.inputText = this.contactNumber;
          this.getPhoneNumberFormat();
        } else {
          this.myProfileForm = this.fb.group({
            phoneNumber: [null, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]],
          });
        }
        document.getElementById('wrapper').style.overflowY = 'hidden';
      }
    }).catch((err) => {
    });
  }

  async viewProfile() {
    this.flag = false;
    if (this.getUserProfileData() !== null) {
      await this.getUserProfileData();
    }
    this.isModalOpen = true;
    this.showMenu = false;
    this.sharedService.focusOnElement('uni');
    this.sharedService.trapFocusInModal('uni', 'chg');
  }

  closeModal() {
    this.updatedEmailValue = this.notifyUpdatesEmail;
    this.updatedSmsValue = this.notifyUpdatesSms;
    this.resolvedEmailValue = this.notifyResolvedEmail;
    this.resolvedSmsValue = this.notifyResolvedSms;
    this.checkedEmailValue = this.notifyCheckedEmail;
    this.checkedSmsValue = this.notifyCheckedSms;
    this.isModalOpen = false;
    document.getElementById('wrapper').style.overflowY = 'auto';
    this.editNumber = true;
    this.rowData = [];
    this.phoneModel.isDisabled = this.editNumber;
    this.sharedService.focusOnElement('pfr');
  }

  onBlurEvent() {
    this.flag = true;
    this.getPhoneNumberFormat();
  }

  getPhoneNumberFormat() {
    const phoneno = /^(\+)?04[0-9]{8,17}$/;
    const phoneNoFormat = /^(\+)?614[0-9]{7,17}$/;
    const value = this.phoneModel.inputText.match(phoneno) ? this.phoneModel.inputText.match(phoneno) : this.phoneModel.inputText.match(phoneNoFormat);
    if (value) {
      this.sms_hide = false;
    } else {
      this.sms_hide = true;
      this.updatedSmsValue = false;
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.options = this.toastr.toastrConfig;
    //this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    //document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }


  subscription: any;
  menuFilterRoleList = [];
  setHamburgerMenuItems() {
    // set roles

    this.commonService.primaryBusinessName.subscribe((defaultBusinessDetails) => {
      const roles: any = this.commonService.getPrimaryValue()['roles'] || [];

      const promise1 = this.commonService.getNavigationList();
      const promise2 = this.commonService.getFunctionDetailsList();
      this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
        const navigationListData: any = result[0];
        const functionDetailsListData: any = result[1];

        this.menuFilterRoleList = [];

        (navigationListData || []).forEach((parentElement) => {
          if (parentElement.noUrl) {
            const childMenu = parentElement.childMenus || [];
            const modifiedChildMenu = [];
            childMenu.forEach((childElement) => {
              const functionRoles = (functionDetailsListData[childElement.name] || '').split(',');
              const commonRoles = functionRoles.filter(item => roles.includes(item));
              if (commonRoles.length > 0) {
                modifiedChildMenu.push(childElement);
              }
            });
            if (modifiedChildMenu.length) {
              parentElement['childMenus'] = modifiedChildMenu;
              this.menuFilterRoleList.push(parentElement);
            }
          } else {
            const functionRoles = (functionDetailsListData[parentElement.parentName] || '').split(',');
            const commonRoles = functionRoles.filter(item => roles.includes(item));
            if (commonRoles.length > 0) {
              this.menuFilterRoleList.push(parentElement);
            }
          }
        })
      }, error => {
        // this.createGridConfig();
        // this.router.navigateByUrl('/error');
      });


    })




  }

  activeMenu = '';
  openServiceDropdownDD(menu) {
    this.activeMenu = menu;
  }

  closeServiceDD() {
    this.activeMenu = '';
  }

}