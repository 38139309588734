<div class="page-bg">
    <div class="container">
        <div class="project-head">
            <h4>FAQ</h4>
        </div>
        <div class="accordion">
            <div class="accordion-item">
                <h2 (click)="accrodianClickOE()" class="accordion-header" id="otherEquipment_0">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#otherEquipment'" aria-expanded="false">
                        1. What capabilities are now in the Portal? </button>
                </h2>
                <div id="otherEquipment" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <ul>
                            <li>Interactive Map: Access a comprehensive map of all Amplitel sites including towers,
                                rooftops, exchanges, landbanks and street pole assets.</li>
                            <li>Visualisation of Digital Twin: Visualise sites with available digital twins via the
                                portal.</li>
                            <li>Access Site Information: Download Preliminary Site Information Report </li>
                            <li>Enquire about Sites: Enquire about site-related information.</li>
                            <li>Site Access Request: View the detailed information about Site Access request and its
                                approval process.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 (click)="accrodianClickOE()" class="accordion-header" id="otherEquipment_1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#acc2'" aria-expanded="false">
                        2. What should I do if I forget my password? </button>
                </h2>
                <div id="acc2" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <ul>
                            <li>Click on the "Forgot Password" link on the login page and follow the instructions to
                                reset your password.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 (click)="accrodianClickOE()" class="accordion-header" id="otherEquipment_1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#acc3'" aria-expanded="false">
                        3. What should I do if I encounter issues with the new features? </button>
                </h2>
                <div id="acc3" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <ul>
                            <li>Email the support team at <a href="mailto:amplitel.IT.Helpdesk@amplitel.com"
                                    target="_blank"> Amplitel.IT.Helpdesk@amplitel.com</a> for assistance OR contact
                                your account manager.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 (click)="accrodianClickOE()" class="accordion-header" id="otherEquipment_1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#acc4'" aria-expanded="false">
                        4. Where can I find the user guides? </button>
                </h2>
                <div id="acc4" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        <ul>
                            <li>User guides are available in the <a
                                    href="Userguide.pdf"
                                    target="_blank">Help Center</a> within the portal.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>