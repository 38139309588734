import { Component, OnDestroy, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment_ from 'moment';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../../grid-filter/custom-date-filter/custom-date-filter.component';
//import { GridTilesCustomerViewComponent } from '../customer/components/grid-tiles-customer-view/grid-tiles-customer-view.component'
import { CompanyNotificationService, INotificationData } from '../company-notification.service';
//import { DashboardService } from '../../dashboard/dashboard.service';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { CustomAgGrid, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { AddPreferenceData, ColumDef } from '../notification.model';
import { forkJoin } from 'rxjs';
//import { SpinnerSVGComponent } from "../../spinner/spinner.component";
import { CommonService } from "../../services/common.service";
import { UsersService } from 'src/app/users/users.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-notification-preferences',
  templateUrl: './notification-preferences.component.html',
  styleUrls: ['./notification-preferences.component.less']
})
export class NotificationPreferencesComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  headerText: string;
  notificationData: any;
  options: GlobalConfig;
  public gridData: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: 'Notifications records',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  gridConfig: GridOptions;
  gridApi: any;
  rowData: any = [];
  subscription: any;
  gridInitialized: boolean;
  subscribedFlag: boolean = false;
  public isSearched: boolean = false;
  private columnDefs: ColumDef[] = [];
  public aemLabelAuthoring: AddPreferenceData;
  public filteredRowData: any = [];
  public businessNames: any;
  userRole = [];
  public businessRoleData: any;
  public cidns: any = [];
  public businessNameItems = [];
  selectedBusinessName: any;
  public businessNameModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness Name Required  Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  moment = moment_;
  hideDropdown: boolean = true;
  disableButton: boolean = true;
          
  constructor(private router: Router, private notificationService: CompanyNotificationService, 
    private commonService: CommonService,
    private dataSvc: UsersService,
    private toastr: ToastrService,
    private titleService: Title,
    private sharedService: SharedService) {
    this.titleService.setTitle('Amplitel Notification Preferences');
    this.userRole = (this.commonService.getroleDetails()).roleList;
    this.getLabels();
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit() {
    this.sharedService.focusOnElement('header_logo');
  }

createGrid(param) {
    if (this.subscribedFlag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedFlag = true;
    }
    //const promise1 = this.columnDefs.length ? this.columnDefs : this.createGridColumns();
    const promise1 = this.createGridColumns();
    const promise2 = this.getNotificationData(param);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.notificationData = this.notificationService.notificationPreferenceData;
      this.rowData = this.notificationData; 
      //if (this.gridApi) {      
       // this.createGridRow();
      //} else {            
        this.createGridConfig();     
      //}
    }, error => {
      this.notificationData = [];
      this.rowData = [];
      //if (this.gridApi) {                
      //  this.createGridRow();
      //} else {
        this.createGridConfig();
      //}
    });
  }

  async createGridColumns() {
    if (this.aemLabelAuthoring &&  this.aemLabelAuthoring.form &&  this.aemLabelAuthoring.form.columDef && this.aemLabelAuthoring.form.columDef.length) {
      this.aemLabelAuthoring.form.columDef.forEach((item) => {
        if (item && item.field === 'edit') {
          item.template = `${item.template}`
        }
      });
      return this.columnDefs = await this.aemLabelAuthoring.form.columDef;
    } else{
      return false;
    }
  }

  gridReady(param) {
    this.gridApi = param.value.event.api;
  }

  createGridRow() {
    const myArray = [];
    if (this.rowData && this.rowData.length) {
      this.rowData.forEach(element => {        
        const _rowData: INotificationData = {
          cidn:'',
          businessName: '',
          notificationtype: '',
          assuranceresolved_email: '',
          edit: ''
        };
        //_rowData.notificationPreference = 'billarrival';
        // if (element.assuranceresolved_email && element.assuranceupdate_email) {
        //   _rowData.notificationPreference = 'All';
        // } else if (element.assuranceupdate_email && !element.assuranceresolved_email) {
        //   _rowData.notificationPreference = 'Notify for all updates';
        // } else if (!element.assuranceupdate_email && element.assuranceresolved_email) {
        //   _rowData.notificationPreference = 'Notify when resolved';
        // } else {
        //   _rowData.notificationPreference = undefined;
        // }
        _rowData.assuranceresolved_email = element.assuranceresolved_email ? element.assuranceresolved_email : '';
        // _rowData.resolutionemailId = element.assuranceresolved_email ? element.assuranceresolved_email : '';
       
        this.businessNames.forEach(ele => {
          if (ele.CIDN === element.cidn) {
            _rowData.businessName = ele['Business name'];
            _rowData.cidn = ele.CIDN;
          }
        });
        // _rowData.notificationtype = element.notificationtype ? (element.notificationtype === 'Fault' || element.notificationtype === 'Assurance') ? 'Assurance' : element.notificationtype : '';
        _rowData.notificationtype = element.notificationtype ? element.notificationtype : 'Billing';
        _rowData.edit = '';
        //if (_rowData.notificationtype !== 'DocumentUpdate') {
          myArray.push(_rowData);
        //}
      });
      this.rowData = myArray;
      // if (this.gridApi) {
      //   this.gridApi.setRowData(this.rowData)
      // }
    } else {
      this.rowData = myArray;
      // if (this.gridApi) {
      //   this.gridApi.setRowData(this.rowData)
      // }
    }
  }

  createGridConfig() {
    this.createGridRow();
    this.gridConfig = <GridOptions>{};
    this.gridConfig.paginationPageSize = 1;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    // this.gridConfig.enableFilter = true;
    // this.gridConfig.enableSorting = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent,
      // FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridData.gridOptions = this.gridConfig;
  }

  navigateToAddPreference(_data, edit?) { 
    if (edit) {
      this.notificationService.setSelectedNotificationsData(_data.data);
      this.router.navigate(['company-notifications-edit-preference']);
    }
    else {
      this.notificationService.setSelectedNotificationsData(null);
      this.router.navigate(['company-notifications-add-preference']);
    }
  }

  // onFilterSelected(filteredData) {
  //   if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
  //     filteredData.filteredRowData.forEach(row => {
  //       if (row.data) {
  //         this.filteredRowData.push(row.data);
  //       }
  //     });
  //   }
  // }

  // 1st
  getLabels() {
    return new Promise((resolve, reject) => {
      this.notificationService.getCompanyNotificationModel().then(() => {
        this.aemLabelAuthoring = this.notificationService.gridLabelAuthoringDataObj;
        this.gridData.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.getBusinessNames();
        this.successMsg();
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  // 2nd
  async getBusinessNames() {
    //const params = { 'page': ' ' };
    const params = { 'infraco': 'false' };
    await this.dataSvc.getBusinessNamesMyUser(params).then(async res => {
      this.businessRoleData = res;   
      this.businessNames = await this.businessRoleData.BusinessNameDetails;
      this.businessNames.forEach(element => {
        //if (this.cidns.indexOf(element['CIDN']) > -1) {
          this.businessNameItems.push(element['Business name']);
        //}
      });
      if (!this.userRole.includes('ampliteladmin')) {
        let params = {
          // cidn: '',
          customerLevel: 'Y'
        }
        this.createGrid(params);
        this.successMsg();
      } else if(this.userRole.includes('ampliteladmin')) {
        this.hideDropdown = false;
      }
      // if (this.businessNameItems.length === 1) {
      //   let cidn;
      //   this.businessNames.forEach(element => {
      //     if (element['Business name'] === this.businessNameItems[0]) {
      //       cidn = element.CIDN;
      //     }
      //   });
      //   let params = {
      //     cidn: '',
      //     customerLevel: 'Y'
      //   }
      //   this.createGrid(params)
      // } else {
      //   this.hideDropdown = false;
      // }
    }).catch((err) => {
    });
  }

  modelValueChange(event) {
    this.selectedBusinessName = event;
    this.disableButton = false;
  }

  getGridData() {
    this.gridInitialized = false;
    this.isSearched = true;
    let cidn;
    this.businessNames.forEach(element => {
      if (element['Business name'] === this.selectedBusinessName) {
        cidn = element.CIDN;
      }
    });
    let params = {
      cidn: cidn,
      customerLevel: 'Y'
    }
    this.createGrid(params);
  }

  getNotificationData(params) {
    return new Promise((resolve, reject) => {
      this.notificationService.getNotificationsData(params).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }

  // download() {
  //   const data = [];
  //   const dataHeaders = ['Business Name', 'Notification type', 'Notify for all updates', 'Notify when resolved'];
  //   const now = this.moment().format('YYYYMMDDHHmmss');
  //   let dataToBeDownloaded = [];
  //   if (this.filteredRowData && this.filteredRowData.length > 0) {
  //     dataToBeDownloaded = this.filteredRowData;
  //   } else {
  //     if (this.rowData && this.rowData.length) {
  //       dataToBeDownloaded = this.rowData;
  //     }
  //   }
  //   dataToBeDownloaded.forEach(selectedRow => {
  //     data.push({
  //       'Business Name': selectedRow.businessName,
  //       'Notification type': selectedRow.notificationtype,
  //       'Notify for all updates': selectedRow.notifymailId,
  //       'Notify when resolved': selectedRow.resolutionemailId
  //     });
  //   });
  //   // tslint:disable-next-line: no-unused-expression
  //   new Angular5Csv(data, 'Notification Preference' + now, { headers: dataHeaders });

  // }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.autoDismiss=true;
    this.options.timeOut=3000;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role','alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live','polite');
  }

  successMsg() {
    if (this.notificationService.addPreferenceFormData) {
      let _successMsg: string;
      if (this.notificationService.addPreferenceFormData.page) {
        if (this.notificationService.addPreferenceFormData.page === 'Create') {
          _successMsg = this.aemLabelAuthoring.form.errorMessages.NotificationCreateMsg;;
        } else if (this.notificationService.addPreferenceFormData.page === 'Modify') {
          _successMsg = this.aemLabelAuthoring.form.errorMessages.NotificationModifyMsg;;
        } else if (this.notificationService.addPreferenceFormData.page === 'Delete') {
          _successMsg = this.aemLabelAuthoring.form.errorMessages.NotificationDeleteMsg;;
        }
        this.showToasterMessage(_successMsg, '', this.options, 'success');
        this.notificationService.addPreferenceFormData.page = '';
        this.notificationService.addPreferenceFormData.data = '';
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscribedFlag) {
      this.subscription.unsubscribe();
    }
  }

}
