<div class="page-bg">
  <div class="row content-row">
  </div>
    <div class="row content-row">
      <div class="popupMessage col-sm-7 col-md-7 col-lg-7">
        <p>Use of the Customer Portal (the Portal) is subject to the
          <span><a class="footer-nav-links" id="ackPopup" href="https://www.amplitel.com.au/terms-use"
              rel="noopener noreferrer" target="_blank" role="link">terms of use</a> </span>
          , as amended by Amplitel from time to time.
        </p>
        <p>The information contained in the Portal is provided for the sole purpose of customers obtaining services from
          Amplitel and must only be accessed, downloaded or utilised for compliant business purposes with Amplitel.</p>
        <p>You must not access, use, share or disclose Amplitel confidential information without Amplitel’s prior
          written consent or without an express right to do so in contract or at law. Amplitel’s confidential
          information includes its customer, technical, operational, billing, pricing and commercial information.</p>
        <p>By accessing the Portal, you are accepting the <span><a class="footer-nav-links"
              href="https://www.amplitel.com.au/terms-use" rel="noopener noreferrer" target="_blank" role="link">terms
              of use</a> </span> and agree to be bound by them.
        </p>

        <button type="submit" (click)="onAcknowledge()"
          class="red-primary-btn acknowledge-submit-btn">
          Acknowledge</button>
          <button tabindex="0" id="cancelDisclaimer" class="red-primary-btn cancelButton"
          (click)="cancel()" 
          (keydown.Enter)="cancel()"
          role="button">Cancel</button>
        <!-- <form [formGroup]="termsForm"> 
          <div class=" footer">

          <label>
            <input  formControlName="cb" type="checkbox" data-toggle="toggle"> &nbsp;I agree to the
            Terms and Conditions
          </label>
          <button type="submit" [disabled]="!termsForm.valid"(click)="onAcknowledge()"
          class="red-primary-btn acknowledge-submit-btn">
          Acknowledge</button>
        </div>
 
      </form> -->
    </div>
  </div>
</div>