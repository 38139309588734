export class AttachmentDetails {
   ContentType: string;
   fileName: string;
   size: string;
   file: any;
}
export class AttachmentMetaData {
   attachmentDetails: AttachmentDetails;
   validationError: string;
   canShowProgressBar: boolean;
   isUploadDone?: string;
}
export class CustomFileUpload {
   id: string;
   formControlNameDescription: string;
   attachmentObj: Array <AttachmentMetaData>;
   fileUploadLabelAuthoring: FileUpload;
}
export class FileUpload {
   fileSizeLimit: string;
   fileSizeLimitMessage: string;
   fileZeroMessage:string;
   fileLimitMessage:string;
   invalidExtensionMessage: string;
   validExtensions: Array<string>;
   fileUnsuccessfulUploadMessage: string;
   fileInfo: string;
   fileuploadHeader: string;
   fileuploadDragText: string;
   fileuploadDragTextViewTicket: string;
   fileuploadBrowseLinkText: string;
   fileuploadDropText: string;
   fileuploadNoFileText: string;
   fileuploadDesktopAttchBtnTxt: string;
   fileUploadviewFileText: string;
   commentPlaceholder: string;
   enquirySuccessMsg:string;
   enquiryUnsuccessMsg:string;
   siteAccess:string;
   ticketSummaryErrMsg:string;
}

export class CustomFileUploadCaseDetails {
   id: string;
   formControlNameDescription: string;
   attachmentObj: Array <AttachmentMetaData>;
   fileUploadLabelAuthoring: FileUploadCaseDetails;
}

export class FileUploadCaseDetails {
   fileSizeLimit: string;
   fileSizeLimitMessage: string;
   fileZeroMessage:string;
   fileSizeLimitMessageCombined:string;
   fileLimitMessage:string;
   invalidExtensionMessage: string;
   validExtensions: Array<string>;
   fileInfo: string;
   fileuploadDragText: string;
   fileuploadDragTextViewTicket: string;
   fileuploadNoFileText: string;
   ticketSummaryErrMsg:string;
}